import React from "react";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ name }) => {
  return (
    <React.Fragment>
      <div className="bread-crumbs">
        <Link to="/" className="item">
          Home
        </Link>
        <Link to="/best-seller" className="item">
          Best Seller
        </Link>
        <span>{name}</span>
      </div>
    </React.Fragment>
  );
};

export default BreadCrumbs;
