import { Container, Row, Col, ListGroup } from "react-bootstrap";

import React from "react";

import { Link, Outlet } from "react-router-dom";

const Admin = () => {
  const [items, setItems] = React.useState([
    {
      name: "Category",
      link: "/admin/category",
      color: "#fff",
    },
    {
      name: "Create Category",
      link: "/admin/createcategory",
      color: "#000",
    },
    {
      name: "Product",
      link: "/admin/product",
      color: "#000",
    },
    {
      name: "Create Product",
      link: "/admin/createproduct",
      color: "#fff",
    },
  ]);
  return (
    <>
      <Container fluid>
        <Row>
          admin
          <Col md={3} className="sidebar">
            <ListGroup defaultActiveKey="#link1">
              <ListGroup.Item
                action
                style={{
                  padding: "10px",
                  background: "transparent",
                  color: "#fff",
                  fontWeight: "bolder",
                  fontSize: "28px",
                  marginBottom: "10px",
                }}
                href="/"
              >
                SPS-Fulfillment
              </ListGroup.Item>
              {items?.map((item) => (
                <ListGroup.Item
                  action
                  style={{ background: "transparent", color: "#fff" }}
                >
                  <Link to={`${item.link}`}>{item.name}</Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={9} className="main-content">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Admin;
