import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";


import TeamData from "../../../Data/OurTeam.json";

import Button from "../Common/Button";

const OurTeam = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="our-team">
        <div className="container">
          <div className="grid" data-gap="80">
            <div className="grid" data-gap="15">
              <div className="section-title"><Trans component={{ br: <br /> }}>Meetourteamtitle</Trans></div>
              <p className="simple-text">
              <Trans component={{ br: <br /> }}>Teamsubtitle</Trans>
              <Trans component={{ br: <br /> }}>Teamtext</Trans>
              </p>
            </div>
            <div className="container">
              <div className="row">
                {TeamData?.map((item) => (
                  <div className="col-md-4 col-sm-6 col-12 col-lg-3">
                    <div className="team-card">
                      <div className="image">
                        <LazyLoadImage
                          src={`/assets/image/${item.image}.png`}
                        />
                      </div>
                      <div>
                        <h4>{item.name}</h4>
                        <p>{item.desi}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="team-members">
              {TeamData.map((item) => (
                <div className="team-card">
                  <div className="image">
                    <LazyLoadImage src={`/assets/image/${item.image}.png`} />
                  </div>
                  <div>
                    <h4>{item.name}</h4>
                    <p>{item.desi}</p>
                  </div>
                </div>
              ))}
            </div> */}
            <div className="button-holder">
              <Button
                className={"btn-primary m-auto"}
                Text={<Trans component={{ br: <br /> }}>Joinourteam</Trans>}
                URL={"arrow-right"}
                onClick={() => navigate("/contact-us")}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OurTeam;
