import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/main.scss";
import Spinner from "react-bootstrap/Spinner";

import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// style
function BasicExample() {
  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

ReactDOM.render(
  <Suspense fallback={<BasicExample />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);

reportWebVitals();
