import React from "react";
import { ReactSVG } from "react-svg";

const Button = ({ Text, Icon, className, URL, onClick, hover, ...props }) => {
  return (
    <button
      className={`btn h-list ${className ? className : "btn-primary"} ${hover}`}
      data-gap="10"
      onClick={onClick}
      {...props}
    >
      {Text}
      {URL ? (
        <ReactSVG className="svg-icon" src={`/assets/icons/${URL}.svg`} />
      ) : (
        ""
      )}
    </button>
  );
};

export default Button;
