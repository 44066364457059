import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PaymentType from "../components/Common/PaymentType";
import ProductSlide from "../components/Pricing/ProductSlide";
import { useTranslation, Trans } from "react-i18next";

const shippingItem = [
  { id: 1, size: "8 x 5 x 10 cm", weight: "3kg", price: "4.99" },
  {
    id: 2,
    size: "28 x 20 x 20 cm",
    weight: "5kg",
    price: "5.15",
  },
  {
    id: 3,
    size: "28 x 25 x 25 cm",
    weight: "10kg",
    price: "5.50",
  },
  {
    id: 4,
    size: "58 x 40 x 18 cm",
    weight: "20kg",
    price: "8.60",
  },
];
const serviceItem = [
  { id: 1, size: "8 x 5 x 10 cm", weight: "3kg", price: "1.00" },
  {
    id: 2,
    size: "28 x 20 x 20 cm",
    weight: "5kg",
    price: "1.20",
  },
  {
    id: 3,
    size: "28 x 25 x 25 cm",
    weight: "10kg",
    price: "1.30",
  },
  {
    id: 4,
    size: "58 x 40 x 18 cm",
    weight: "20kg",
    price: "1.90",
  },
];
const Pricing = () => {
  return (
    <React.Fragment>
      <div className="pricing-page">
        <div className="pricing-banner">
          <div className="container-sm">
            <div className="grid" data-gap="80">
              <div className="grid text-center" data-gap="15">
                <h1><Trans component={{ br: <br /> }}>Pricingtitle</Trans></h1>
                <p className="paragraph">
                <Trans component={{ br: <br /> }}>Pricingsubtitle</Trans>
                </p>
              </div>
              <PaymentType />
            </div>
          </div>
        </div>
        <div className="py-120">
          <div className="container">
            <div className="row gy-2">
              <div className="col-12">
                <ProductSlide
                  title={<Trans component={{ br: <br /> }}>Shippingcosttitle</Trans>}
                  desc={
                    <Trans component={{ br: <br /> }}>Shippingcostsubtitle</Trans>
                  }
                  OrderType={"inverse"}
                  DescIcon={"shiping-cost"}
                  background="#21BBFC"
                  image="/assets/image/order-item-1.png"
                  color="#30A7F2"
                  Items={shippingItem}
                />
              </div>
              <div className="divider my-5"></div>
              <div className="col-12">
                <ProductSlide
                  title={ <Trans component={{ br: <br /> }}>Handlingfeetitle</Trans>}
                  desc={
                    <Trans component={{ br: <br /> }}>Handlingfeesubtitle</Trans>
                  }
                  OrderType={"reverse"}
                  DescIcon={"handling-fees"}
                  background="#A120FC"
                  image="/assets/image/order-item-2.png"
                  color="#7E00D7"
                  Items={serviceItem}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="graph-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="lh-lg"><Trans component={{ br: <br /> }}>Titlemap</Trans></h2>
                <div
                  style={{
                    position: "relative",
                    minWidth: "20px",
                    maxHeight: "500px", // May be any value
                  }}
                >
                 
                  <LazyLoadImage src="/assets/image/graph.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
