import React, {  useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import Button from "../components/Common/Button";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
const Order = () => {
  const navigate = useNavigate();
  const form = useRef();
  const [loading, setLoading] = useState(false);

  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      image: "",
    },
  });
  const onSubmit = (data) => {
    // let body = {
    //   to_name: "spsfulfillments",
    // };
    // data.name && (body.name = data.name);
    // data.from_name && (body.from_name = data.name);
    // data.assistance && (body.assistance = data.assistance);
    // data.contact && (body.contact = data.contact);
    // data.link && (body.link = data.link);
    // data.phonenumber && (body.phonenumber = data.phonenumber);
    // data.product && (body.product = data.product);
    // data.quantity && (body.quantity = data.quantity);
    // data.services && (body.services = data.services);
    // data.special && (body.special = data.special);
    // data.variation && (body.variation = data.variation);
    // data.delivery[0] && (body.delivery1 = data.delivery[0]);
    // data.delivery[1] && (body.delivery2 = data.delivery[1]);
    // data.image[0] && (body.image = data.image[0]);
    // console.log(form.current, body);
    setLoading(true);
    emailjs
      .sendForm(
        "service_8fk3qjw",
        "template_ltlb90c",
        form.current,
        "lDpMvVmtgtud1otoM"
      )
      .then(
        (result) => {
          if (result.text) {
            setTimeout(() => {
              setLoading(false);
              navigate("/thanks");
            }, 2000);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
  };
  const loader = (
    <div className="loading">
      <div className="spinner">
        <div
          className="spinner-border text-light bg-dark"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="order">
        <div className="order-banner">
          {loading ? loader : null}
          <div className="container-sm">
            <div className="grid" data-gap="15px">
              <h1><Trans component={{ br: <br /> }}>tileorderpage</Trans></h1>
              <p>
              <Trans component={{ br: <br /> }}>subtitleorderpage</Trans>
                {/* <br /> send us a message and we will reply ASAP */}
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} ref={form}>
              <div className="grid" data-gap="40">
                <div className="grid" data-gap="80">
                  <div className="row g-5">
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>name</Trans></label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter your name"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>phonenumber</Trans></label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="+39"
                          {...register("phonenumber", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row g-5">
                    <div className="col-md-8 col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>whatproduct</Trans></label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Describe the product you are looking for"
                          {...register("product", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>productquantity</Trans></label>
                        <input
                          type="number"
                          className="form-control form-controler"
                          placeholder="Product Quantity"
                          {...register("quantity", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>productvariation</Trans></label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Color, Size, Plug..."
                          {...register("variation")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>SpecialRequest</Trans></label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Customization, ThankYouCards, Packaging"
                          {...register("special")}
                        />
                      </div>
                      <div className="form-text">
                      <Trans component={{ br: <br /> }}>SpecialRequesttext</Trans>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h4><Trans component={{ br: <br /> }}>deliverydetails</Trans></h4>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineRadio1"
                          value="standardDelivery"
                          {...register("delivery")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          <Trans component={{ br: <br /> }}>StandardDelivery</Trans>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          {...register("delivery")}
                          id="inlineRadio2"
                          value="cashOnDelivery"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          <Trans component={{ br: <br /> }}>CashonDelivery</Trans>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h4><Trans component={{ br: <br /> }}>Services</Trans></h4>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="services1"
                          value="completeService"
                          {...register("services")}
                        />
                        <label className="form-check-label" htmlFor="services1">
                        <Trans component={{ br: <br /> }}>CompleteService</Trans>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          {...register("services")}
                          id="services2"
                          value="sendYourGoodsToOurWarehouse"
                        />
                        <label className="form-check-label" htmlFor="services2">
                        <Trans component={{ br: <br /> }}>SendyourgoodstoourwarehouseServices</Trans>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="services3"
                          value="sendOurGoodsToYourWarehouse"
                          {...register("services")}
                        />
                        <label className="form-check-label" htmlFor="services3">
                        <Trans component={{ br: <br /> }}>Sendourgoodstoyourwarehouse</Trans>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h4><Trans component={{ br: <br /> }}>SaleAssistance</Trans></h4>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          {...register("assistance")}
                          id="assistance1"
                          value="Yes"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="assistance1"
                        >
                          <Trans component={{ br: <br /> }}>Yes</Trans>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="assistance2"
                          value="No"
                          {...register("assistance")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="assistance2"
                        >
                          <Trans component={{ br: <br /> }}>No</Trans>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h4><Trans component={{ br: <br /> }}>Howcontact</Trans></h4>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          {...register("customercontact")}
                          id="customercontact1"
                          value="phonenumber"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customercontact1"
                        >
                          <Trans component={{ br: <br /> }}>phonenumber</Trans>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="customercontact2"
                          value="whatsappnumber"
                          {...register("customercontact")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customercontact2"
                        >
                          <Trans component={{ br: <br /> }}>WhatsAppNumber</Trans>
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="customercontact2"
                          value="email"
                          {...register("customercontact")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customercontact2"
                        >
                          <Trans component={{ br: <br /> }}>email</Trans>
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter your E-mail"
                          {...register("contact")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>addphoto</Trans></label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter the Product Link(aliexpress, amazon..)"
                          {...register("link")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="input-group mb-3"
                        style={{
                          background: "rgba(255, 255, 255, 0.1)",
                          border: "1px dashed rgba(255, 255, 255, 0.4)",
                          borderRadius: "8px",
                          padding: "16px",
                          gap: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100px",
                        }}
                      >
                        <input
                          type="file"
                          id="inputGroupFile02"
                          hidden
                          {...register("image")}
                        />
                        <label
                          htmlFor="inputGroupFile02"
                          style={{ fontSize: "14px" }}
                        >
                          {watch("image") ? (
                            <p>{getValues("image")[0]?.name}</p>
                          ) : (
                            <p><Trans component={{ br: <br /> }}>upload</Trans></p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-holder d-flex justify-content-center">
                  <Button
                    Text={<Trans component={{ br: <br /> }}>SendQuestion</Trans>}
                    className={"btn-primary"}
                    URL={"arrow-right"}
                    type="submit"
                    disabled={!!loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Order;
