export function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"icon-rounded slick-arrow-icon slick-icon-next"}
      onClick={onClick}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.835 21.9563C16.6133 21.9563 16.3916 21.8746 16.2166 21.6996C15.8783 21.3613 15.8783 20.8013 16.2166 20.463L22.68 13.9996L16.2166 7.53631C15.8783 7.19798 15.8783 6.63798 16.2166 6.29965C16.555 5.96132 17.115 5.96132 17.4533 6.29965L24.535 13.3813C24.8733 13.7196 24.8733 14.2796 24.535 14.618L17.4533 21.6996C17.2783 21.8746 17.0566 21.9563 16.835 21.9563Z"
          fill="#34383C"
        />
        <path
          d="M23.718 14.875H4.08301C3.60467 14.875 3.20801 14.4783 3.20801 14C3.20801 13.5217 3.60467 13.125 4.08301 13.125H23.718C24.1963 13.125 24.593 13.5217 24.593 14C24.593 14.4783 24.1963 14.875 23.718 14.875Z"
          fill="#34383C"
        />
      </svg>
    </div>
  );
}

export function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={"icon-rounded slick-arrow-icon slick-icon-prev"}
      onClick={onClick}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.165 21.9563C11.3867 21.9563 11.6084 21.8746 11.7834 21.6996C12.1217 21.3613 12.1217 20.8013 11.7834 20.463L5.32002 13.9996L11.7834 7.53631C12.1217 7.19798 12.1217 6.63798 11.7834 6.29965C11.445 5.96132 10.885 5.96132 10.5467 6.29965L3.46502 13.3813C3.12669 13.7196 3.12669 14.2796 3.46502 14.618L10.5467 21.6996C10.7217 21.8746 10.9434 21.9563 11.165 21.9563Z"
          fill="#34383C"
        />
        <path
          d="M4.28199 14.875H23.917C24.3953 14.875 24.792 14.4783 24.792 14C24.792 13.5217 24.3953 13.125 23.917 13.125H4.28199C3.80366 13.125 3.40699 13.5217 3.40699 14C3.40699 14.4783 3.80366 14.875 4.28199 14.875Z"
          fill="#34383C"
        />
      </svg>
    </div>
  );
}
