import React from "react";
import { Table } from "react-bootstrap";
import { BsCheck, BsX, BsInfoCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button";
const features = [
  { name: "Spedizioni in 24/48h", basic: true, pro: true, premium: true },
  {
    name: "Spedizione in tutta Europa",
    basic: true,
    pro: false,
    premium: true,
  },
  { name: "Contrassegno", basic: true, pro: true, premium: false },
  { name: "Costo Spedizione", basic: "4.99€", pro: "5.00€", premium: "5.50€" },
  { name: "Costo Servizio", basic: "1.00€", pro: "1.00€", premium: "1.00€" },
  {
    name: "Assistenza e gestione client",
    basic: true,
    pro: false,
    premium: true,
  },
  { name: "Ricerca Prodotto", basic: true, pro: false, premium: false },
  {
    name: "Aquisto stock per il cliente",
    basic: true,
    pro: false,
    premium: false,
  },
  { name: "Vendita Assistita", basic: true, pro: false, premium: false },
];
const pricingData = [
  {
    title: "SPS Fulfillment",
    price: "€9.99",
    features: [
      { name: "Feature 1", included: true },
      { name: "Feature 2", included: true },
      { name: "Feature 3", included: true },
      { name: "Feature 4", included: true },
      { name: "Feature 5", included: true },
      { name: "Feature 6", included: true },
      { name: "Feature 7", included: true },
      { name: "Feature 8", included: true },
    ],
  },
  {
    title: "Drop Deluxe",
    price: "€19.99",
    features: [
      { name: "Pro 1", included: true },
      { name: "Pro 2", included: true },
      { name: "Pro 3", included: true },
      { name: "Pro 4", included: false },
      { name: "Pro 5", included: true },
      { name: "Pro 6", included: true },
      { name: "Pro 7", included: true },
      { name: "Pro 8", included: true },
    ],
  },
  {
    title: "Elogy",
    price: "€29.99",
    features: [
      { name: "Premium 1", included: false },
      { name: "Premium 2", included: false },
      { name: "Premium 3", included: false },
      { name: "Premium 4", included: false },
      { name: "Premium 5", included: false },
      { name: "Premium 6", included: false },
      { name: "Premium 7", included: false },
      { name: "Premium 8", included: false },
    ],
  },
];

const PricingTable = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: "#19202A",
        color: "#fff",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Table striped="columns" responsive>
              <thead>
                <tr>
                  <th>
                    <h2 className="text-white">Benefici</h2>
                  </th>
                  {pricingData.map((item, index) => (
                    <th
                      key={index}
                      style={{
                        backgroundColor: index == 0 ? "#04CBAA" : "",
                        color: index == 0 ? "#19202A" : "",
                        boxShadow: "0px 12px 40px rgba(4 193 161, 0.08)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <h3 className="text-white text-center">{item.title}</h3>
                      {/* <h4 className="text-white text-center">{item.price}</h4> */}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {features?.map((item, index) => (
                  <tr key={index}>
                    <td className="text-white">
                      <div className="d-flex justify-content-between">
                        <p>{item.name}</p>
                        <p>
                          <BsInfoCircle />
                        </p>
                      </div>
                    </td>
                    <td
                      className="text-center"
                      style={{
                        backgroundColor: "#04CBAA",
                        boxShadow: "0px 12px 40px rgba(4 193 161, 0.08)",
                      }}
                    >
                      {item.basic === "4.99€" || item.basic === "1.00€" ? (
                        item.basic
                      ) : item.basic ? (
                        <BsCheck
                          className=" fs-2"
                          style={{ color: "#19202A" }}
                        />
                      ) : (
                        <BsX className=" fs-2" style={{ color: "#19202A" }} />
                      )}
                    </td>
                    <td className="text-center text-white">
                      {typeof item.pro === "string" ? (
                        item.pro
                      ) : item.pro ? (
                        <BsCheck className="text-white fs-2" />
                      ) : (
                        <BsX className="text-white fs-2" />
                      )}
                    </td>
                    <td className="text-center text-white">
                      {typeof item.premium === "string" ? (
                        item.premium
                      ) : item.premium ? (
                        <BsCheck className="text-white fs-2" />
                      ) : (
                        <BsX className="text-white fs-2" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td
                    style={{
                      backgroundColor: "#04CBAA",
                      boxShadow: "0px 12px 40px rgba(4 193 161, 0.08)",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <Button
                      className={"btn-dark m-auto bg-dark p-2 px-4"}
                      Text={"Get Started"}
                      onClick={() => navigate("/order")}
                    />
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
