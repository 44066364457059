import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Button from "../components/Common/Button";
import Cookies from "js-cookie";
import NavDropdown from "react-bootstrap/NavDropdown";
import { MdLanguage } from "react-icons/md";

import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import i18n from "../../i18n";

import { useTranslation, Trans } from "react-i18next";
import { Offcanvas, Nav, Form } from "react-bootstrap";
const handleCase = (item) => {
  switch (item) {
    case "/pricing":
    case "/our-services":
    case "/product-research":
    case "/order":
    case "/":
      return true;
    default:
      return false;
  }
};
const Header = () => {
  const currentLocale = Cookies.get("i18next") || "it";
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleToggle = () => setShow(!show);
  // console.log(show, "first time");
  const [open, setOpen] = useState("#fff");
  const [language, setLanguage] = useState(currentLocale);

  // const [screenSize, getDimension] = useState({
  //   dynamicWidth: window.innerWidth,
  //   dynamicHeight: window.innerHeight,
  // });
  // const setDimension = () => {
  //   getDimension({
  //     dynamicWidth: window.innerWidth,
  //     dynamicHeight: window.innerHeight,
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", setDimension);
  //   if (screenSize.dynamicWidth < 900) {
  //     setShow(true);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", setDimension);
  //   };
  // }, []);

  const handleLanguage = (event) => {
    event = event.replace("#", "");

    setLanguage(event);

    i18n.changeLanguage(event);
  };

  return (
    <>
      <Navbar
        key={"md"}
        bg="transparent"
        expand="lg"
        style={{ position: "absolute", zIndex: 1, width: "100%" }}
        className="mb-3"
      >
        <Container>
          <Navbar.Brand style={{ flexGrow: 1 }}>
            <Link to="/">
              <p
                className={`logo text-${
                  open !== "#000" ||
                  location.pathname === "/order" ||
                  location.pathname === "/"
                    ? "white"
                    : "dark"
                }`}
              >
                {t("appname")}
              </p>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${"md"}`}
            style={{ color: "#fff", background: open !== "#000" ? "#fff" : "" }}
            onClick={handleToggle}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"md"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
            placement="end"
            show={show}
          >
            <Offcanvas.Header closeButton show={show} onHide={handleClose}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"md"}`}>
                <Link to="/">
                  <p className={`logo text-white`}>{t("appname")}</p>
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body show={show}>
              <Nav className="flex-grow-1 pe-3">
                {t("headers", { returnObjects: true })?.map((item) => (
                  <li
                    key={item.link}
                    className="nav-item"
                    style={{
                      borderBottom:
                        location?.pathname === item.link
                          ? `1px solid ${item.color}`
                          : "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShow(false);
                      navigate(item.link);
                    }}
                  >
                    {/* <Link to={item.link}> */}
                    <a
                      className={`nav-link btn-scale ${
                        location?.pathname === item.link ? "active" : ""
                      } ${handleCase(location?.pathname) ? "text-white" : ""}`}
                      onClick={() => {
                        setOpen(item.color);
                        // handleToggle();
                      }}
                    >
                      {item.name}
                    </a>
                    {/* </Link> */}
                  </li>
                ))}

                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={
                    <MdLanguage
                      size={24}
                      color={
                        open !== "#000" ||
                        location.pathname === "/order" ||
                        location.pathname === "/"
                          ? "#fff"
                          : "#000"
                      }
                    />
                  }
                  style={{
                    color: handleCase(location?.pathname) ? "#fff" : "#000",
                  }}
                  menuVariant={
                    handleCase(location?.pathname) ? "light" : "dark"
                  }
                  className="mx-2 text-light"
                  onSelect={handleLanguage}
                >
                  <NavDropdown.Item href="#it">It</NavDropdown.Item>
                  <NavDropdown.Item href="#en">En</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <div className="button-holder">
                <Button
                  Text={<Trans component={{ br: <br /> }}>FooterOrderNow</Trans>}
                  URL={"arrow-right"}
                  onClick={() => {
                    setOpen("#fff");
                    navigate("/order");
                    if (show) {
                      handleToggle();
                    }
                  }}
                  hover="btn-hover"
                  style={{ padding: "10px 24px" }}
                />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
