const Thanks = () => {
  return (
    <div className="thanks-banner">
      <div className="container my-2">
        <div className="row">
          <div className="col-12">
            <h3 className="fs-semibold">
              Thank you for reaching out to us! <br />
              We have received your message and will respond to you within 24
              hours.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Thanks;
