import Table from "react-bootstrap/Table";
import { useState, useEffect } from "react";
import { fireDB } from "../../../config/firebase";
import { getDocs, collection, doc, deleteDoc } from "firebase/firestore";
import { Container } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
function ResponsiveExample() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts().then((res) => {
      setProducts(res);
    });
  }, []);

  async function getProducts() {
    var dataforTheProducts = [];
    const productForMenu = collection(fireDB, "products");
    const docOfMenuProducts = await getDocs(productForMenu);
    docOfMenuProducts.forEach((doc) => {
      dataforTheProducts.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return dataforTheProducts;
  }
  const handleDelete = async (docId) => {
    const delRef = doc(fireDB, "products", docId);
    const chec = await deleteDoc(delRef);
    alert("delete");
    window.location.reload();
  };

  return (
    <Container fluid>
      <h3 className="fs-3 fw-bold lh-lg">Products</h3>
      <Table responsive hover striped="column" bordered>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>saleprice</th>
            <th>costprice</th>
            <th>vendor</th>
            <th>store</th>
            <th>profit</th>
            <th>margin</th>
            <th>sale</th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item?.name}</td>
              <td>{item?.saleprice}</td>
              <td>{item?.costprice}</td>
              <td>{item?.vendor}</td>
              <td>{item?.store}</td>
              <td>{item?.profit}</td>
              <td>{item?.margin}</td>
              <td>{item?.sale}</td>

              <td>
                <a onClick={() => navigate("/admin/createproduct/" + item.id)}>
                  <BiEditAlt size={24} color="#181D2B" />
                </a>
                <a onClick={() => handleDelete(item.id)}>
                  <AiFillDelete size={24} color="#181D2B" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ResponsiveExample;
