import React, { Component } from "react";
import Chart from "react-apexcharts";
import { ReactSVG } from "react-svg";
const Donut = ({ product }) => {
  const series = [
    parseInt(product?.totalusers) ? parseInt(product?.totalusers) : 5230,
    parseInt(product?.realtimeusers) ? parseInt(product?.realtimeusers) : 4678,
  ];
  const options = {
    chart: {
      type: "donut",
      height: 350,
      width: "100%",
    },
    labels: ["Realtime Users", "Total Users"],
    colors: ["#00bcd4", "#ff9800"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " users";
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
    },
  };

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-header bg-white d-flex align-items-center">
        <div className="icon-rounded bg-transparent">
          <ReactSVG src="/assets/icons/user.svg" />
        </div>
        <div className="ml-2 p-2">
          <h3 className="fw-bold">Audience by Country</h3>
          <p className="fw-light lh-lg">Last 7 days</p>
        </div>
      </div>
      <Chart options={options} series={series} type="donut" />
    </div>
  );
};

export default Donut;
