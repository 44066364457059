import React, { useEffect } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import Button from "../components/Common/Button";
import ProductSearch from "../components/ProductSearch";
import ProductList from "../components/Seller/ProductList";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { getDocs, collection } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { useTranslation, Trans } from "react-i18next";

const BestSeller = () => {
  const [lists, setLists] = useState([]);
  const [load, setLoad] = useState(6);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategories().then((res) => {
      setCategories(res);
    });
  }, []);

  async function getCategories() {
    var dataforTheCategories = [];
    const productForMenu = collection(fireDB, "categories");
    const docOfMenuCategories = await getDocs(productForMenu);
    docOfMenuCategories.forEach((doc) => {
      dataforTheCategories.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return dataforTheCategories;
  }
  useEffect(() => {
    getProducts().then((res) => {
      setProducts(res);
      setLists(res);
    });
  }, []);

  async function getProducts() {
    var dataforTheProducts = [];
    const productForMenu = collection(fireDB, "products");
    const docOfMenuProducts = await getDocs(productForMenu);
    docOfMenuProducts.forEach((doc) => {
      dataforTheProducts.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return dataforTheProducts;
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    document.body.classList.add("active-header");
    return function cleanup() {
      document.body.classList.remove("active-header");
    };
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const onSubmit = (data) => {
    const filteredData = products.flatMap(({ name, categories, ...props }) => {
      const filteredItems = categories.filter((item) => {
        if (data?.search && data?.category) {
          return (
            name.toLowerCase().includes(data?.search?.toLowerCase()) ||
            data?.category?.some((value) => item.name.includes(value))
          );
        } else if (data?.search) {
          return name.toLowerCase().includes(data?.search?.toLowerCase());
        } else if (data?.category) {
          return data?.category?.some((value) => item.name.includes(value));
        } else {
          return { ...item };
        }
      });
      return filteredItems.length > 0
        ? { name, categories: filteredItems, ...props }
        : [];
    });

    if (filteredData?.length) {
      setProducts(filteredData);
    } else {
      setProducts(lists);
    }
  };

  const filters = (
    <div className="filters">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header><Trans component={{ br: <br /> }}>sortproduct</Trans></Accordion.Header>
          <Accordion.Body>
            <div className="grid" data-gap="15">
              <div className="h-list" data-gap="15">
                <input
                  type={"checkbox"}
                  {...register("category")}
                  value="Best Seller"
                />
                <span><Trans component={{ br: <br /> }}>category1</Trans></span>
              </div>
              <div className="h-list" data-gap="15">
                <input
                  type={"checkbox"}
                  {...register("category")}
                  value="Best Rating"
                />
                <span><Trans component={{ br: <br /> }}>category2</Trans></span>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header><Trans component={{ br: <br /> }}>categorytitle</Trans></Accordion.Header>
          <Accordion.Body>
            <div className="grid" data-gap="15">
              {categories
                ?.map((item) => (
                  <div className="h-list" data-gap="15">
                    <input
                      type={"checkbox"}
                      {...register("category")}
                      value={item?.name}
                    />
                    <span>{item?.name}</span>
                  </div>
                ))
                ?.filter(
                  (item) =>
                    item.name !== "Best Seller" || item.name !== "Best Rating"
                )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="my-4">
        <Button
          className={"btn-primary m-auto"}
          Text={<Trans component={{ br: <br /> }}>Filter</Trans>}
          type="submit"
        />
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div className="best-seller">
        <div className="seller-banner">
          <div className="container">
            <h1><Trans component={{ br: <br /> }}>Bestsellertitle</Trans></h1>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="products">
            <div className="container">
              <div className="row">
                <div className="col-sm-10 col-xs-10 col-sm-10 col-md-12 col-lg-12">
                  <ProductSearch setValue={setValue} categories={categories} />
                </div>
                <div
                  className="d-sm-block  d-md-none col-sm-2 col-xs-2"
                  style={{ margin: "-25px auto 80px" }}
                >
                  <Button
                    className={"btn-primary m-auto"}
                    Text={<Trans component={{ br: <br /> }}>Filter</Trans>}
                    onClick={handleShow}
                    type="button"
                  />

                  <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Filter</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>{filters}</Offcanvas.Body>
                  </Offcanvas>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-4 col-sm-4 col-12 d-sm-block d-none">
                  {filters}
                </div>
                <div className="col-md-8 col-12 col-sm-8">
                  <ProductList List={products} setLoad={setLoad} load={load} />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default BestSeller;
