import React from "react";
import { ReactSVG } from "react-svg";
import { useTranslation, Trans } from "react-i18next";

const PaymentType = () => {
  return (
    <React.Fragment>
      <section className="payment-type">
        <div className="container-sm">
          <div className="grid" data-gap="25">
            <div className="h-list flex-lg-nowrap" data-gap="25">
              <div className="card payment-method card-hover">
                <div className="card-body">
                  <div className="grid" data-gap="15">
                    <div className="icon-rounded bg-white text-black">
                      <ReactSVG src="/assets/icons/box.svg" />
                    </div>
                    <div>
                      <h4> <Trans component={{ br: <br /> }}>NormalDelivery</Trans></h4>
                      <p>
                      <Trans component={{ br: <br /> }}>normaldeliverycard</Trans>
                      </p>
                    </div>
                    <ul>
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span><Trans component={{ br: <br /> }}>timeShipping</Trans></span>
                      </li>
                    </ul>
                    <div className="spacer"></div>
                    <div className="price">
                    <Trans component={{ br: <br /> }}>Startingat</Trans> &nbsp;<span>€4,99</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card payment-method card-hover">
                <div className="card-body">
                  <div className="grid" data-gap="15">
                    <div className="icon-rounded bg-white text-black">
                      <ReactSVG src="/assets/icons/money-time.svg" />
                    </div>
                    <div>
                      <h4><Trans component={{ br: <br /> }}>CashOnDelivery</Trans></h4>
                      <p>
                      <Trans component={{ br: <br /> }}>cashdeliverycard</Trans>
                      </p>
                    </div>
                    <ul>
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span><Trans component={{ br: <br /> }}>timeShipping</Trans></span>
                      </li>
                    </ul>
                    <div className="spacer"></div>
                    <div className="price">
                    <Trans component={{ br: <br /> }}>Startingat</Trans>  &nbsp;<span>€7,99</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card payment-method card-hover">
              <div className="card-body">
                <div className="grid" data-gap="25">
                  <h5><Trans component={{ br: <br /> }}>Benefits</Trans></h5>
                  <div className="h-list justify-content-between" data-gap="10">
                    <ul className="grid" data-gap="10">
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span><Trans component={{ br: <br /> }}>Benefit1</Trans></span>
                      </li>
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span><Trans component={{ br: <br /> }}>Benefit2</Trans></span>
                      </li>
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span><Trans component={{ br: <br /> }}>Benefit3</Trans></span>
                      </li>
                    </ul>
                    <ul className="grid" data-gap="10">
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span>
                        <Trans component={{ br: <br /> }}>Benefit4</Trans>
                        </span>
                      </li>
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span><Trans component={{ br: <br /> }}>Benefit5</Trans></span>
                      </li>
                      <li className="h-list" data-gap="10">
                        <ReactSVG src="/assets/icons/tick.svg" />
                        <span><Trans component={{ br: <br /> }}>Benefit6</Trans></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PaymentType;
