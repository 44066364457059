import React, { Component } from "react";
import Chart from "react-apexcharts";
import { ReactSVG } from "react-svg";
const HorizontalChart = ({ product }) => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "United States",
        "Argentina",
        "Spain",
        "Columbia",
        "China",
        "Germany",
      ],
    },
    colors: ["#00bcd4"],
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Sales";
        },
      },
    },
  };

  const series = [
    {
      name: "Sales",
      data: [
        parseInt(product?.unitedstateusers)
          ? parseInt(product?.unitedstateusers)
          : 3244,
        parseInt(product?.argentinausers)
          ? parseInt(product?.argentinausers)
          : 5456,
        parseInt(product?.spainusers) ? parseInt(product?.spainusers) : 983,
        parseInt(product?.columbiausers)
          ? parseInt(product?.columbiausers)
          : 353,
        parseInt(product?.chinausers) ? parseInt(product?.chinausers) : 234,
        parseInt(product?.germanyusers) ? parseInt(product?.germanyusers) : 123,
      ],
    },
  ];

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-header bg-white d-flex align-items-center">
        <div className="icon-rounded bg-transparent">
          <ReactSVG src="/assets/icons/globe.svg" />
        </div>
        <div className="ml-2 p-2">
          <h3 className="fw-bold">Audience Engagement</h3>
          <p className="fw-light lh-lg">Last 7 days</p>
        </div>
      </div>
      <Chart options={options} series={series} type="bar" />
    </div>
  );
};

export default HorizontalChart;
