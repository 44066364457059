import React, { useState } from "react";
import Button from "../Common/Button";
import { useTranslation, Trans } from "react-i18next";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import OrderProcessCard from "../Cards/OrderProcessCard";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const OrderProcess = () => {
  let [slider] = useState(null);
  function next() {
    slider.slickNext();
  }
  function previous() {
    slider.slickPrev();
  }
  const navigate = useNavigate();
  const { t } = useTranslation();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,

    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <section className="order-process py-120">
        <div className="container">
          <div className="grid" data-gap="80">
            <div className="h-list justify-content-between g-2" data-gap="10">
              <h3 className="section-title">
                <Trans component={{ br: <br /> }}>Orderprocesstitle</Trans>
              </h3>
              <Button
                className={"btn-primary"}
                Text={<Trans component={{ br: <br /> }}>contact</Trans>}
                URL={"arrow-right"}
                onClick={() => navigate("/contact-us")}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row d-block d-sm-none gy-5"
            style={{ position: "relative" }}
          >
            <div
              className="col-12"
              style={{ position: "absolute", top: 200, left: 0, zIndex: 1000 }}
            >
              <div className="justify-content-between d-flex">
                <button
                  onClick={previous}
                  style={{
                    border: "1px solid #24B8FC",
                    borderRadius: "50%",
                    paddingBottom: "5px",
                    background: "#24B8FC",
                  }}
                >
                  <BsChevronLeft color="#fff" />
                </button>
                <button
                  onClick={next}
                  style={{
                    border: "1px solid #24B8FC",
                    borderRadius: "50%",
                    paddingBottom: "5px",
                    background: "#24B8FC",
                  }}
                >
                  <BsChevronRight color="#fff" />
                </button>
              </div>
            </div>
            <Slider {...settings} ref={(c) => (slider = c)}>
              {t("order", { returnObjects: true }).map((item) => (
                <OrderProcessCard
                  key={item.id}
                  StepImage={item.preview}
                  title={item.name}
                  desc={item.description}
                  anchor={item.anchor}
                  route={item.route}
                />
              ))}
            </Slider>
          </div>
        </div>
        <div className="order-container d-none d-sm-block">
          <Scrollbars
            renderTrackVertical={({
              style: { background, ...style },
              ...props
            }) => (
              <div
                {...props}
                className="track-vertical"
                style={{
                  ...style,
                  background: "#2FA3FC",
                  "&::-webkit-scrollbar-track": {
                    background: "blue",
                  },
                }}
              />
            )}
            style={{
              "&::-webkit-scrollbar-track": {
                background: "blue",
              },
              // scrollSnapType: "x mandatory",
              scrollBehavior: "smooth",
            }}
            renderThumbVertical={({
              style: { background, ...style },
              ...props
            }) => (
              <div
                {...props}
                className="thumb-vertical"
                style={{ ...style, background: "#2FA3FC" }}
              />
            )}
            autoHeight
            autoHeightMin={"auto"}
            autoHeightMax={"auto"}
          >
            <div className="order-process-list">
              {t("order", { returnObjects: true }).map((item) => (
                <OrderProcessCard
                  key={item.id}
                  StepImage={item.preview}
                  title={item.name}
                  desc={item.description}
                  anchor={item.anchor}
                  route={item.route}
                />
              ))}
            </div>
          </Scrollbars>
        </div>
      </section>
    </React.Fragment>
  );
};

export default OrderProcess;
