import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation, Trans } from "react-i18next";

const AboutBanner = () => {

  return (
    <React.Fragment>
      <div className="about-banner">
        <div className="container">
            <div>
              <h1><Trans component={{ br: <br /> }}>Aboutustitle</Trans></h1>
              <div className="image">
                <LazyLoadImage src="/assets/image/about-banner.jpg" />
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutBanner;
