import React, { useEffect, useRef, useState } from "react";
import {
  setDoc,
  doc,
  getDoc,
  addDoc,
  collection,
  getDocs,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import Button from "../../components/Common/Button";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { fireDB } from "../../../config/firebase";
import imageUpload from "../../../config/imageUpload";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";

const Product = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const [inputData, setInputData] = React.useState({
    file: "",
    image: "",
    field: [],
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm({
    name: "",
    description: "",
    image: null,
    costprice: 34.19,
    saleprice: 64.0,
    categories: [],
    vendor: 10,
    profit: 16.41,
    store: 87,
    margin: 55,
    sale: "2.8k",
    file: "",
    suppliers: 12,
    realtimeusers: 200,
    totalusers: 200,
    unitedstateusers: 200,
    argentinausers: 200,
    spainusers: 200,
    columbiausers: 200,
    chinausers: 200,
    germanyusers: 200,
  });
  const navigate = useNavigate();
  const params = useParams();
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategories().then((res) => {
      setCategories(res);
    });
  }, []);

  async function getCategories() {
    var dataforTheCategories = [];
    const productForMenu = collection(fireDB, "categories");
    const docOfMenuCategories = await getDocs(productForMenu);
    docOfMenuCategories.forEach((doc) => {
      dataforTheCategories.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return dataforTheCategories;
  }
  useEffect(() => {
    if (params.productId) {
      handleProduct();
    }
  }, [params]);
  async function handleProduct() {
    const docRef = doc(fireDB, "products", params.productId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let inputData = { ...docSnap.data(), id: docSnap.id };

      setValue("name", inputData?.name);
      setValue("description", inputData?.description);
      setValue("saleprice", inputData?.saleprice);
      setValue("costprice", inputData?.costprice);
      setValue("margin", inputData?.margin);
      setValue("profit", inputData?.profit);
      setValue("suppliers", inputData?.suppliers);
      setValue("vendor", inputData?.vendor);
      setValue("store", inputData?.store);
      setValue("sale", inputData?.sale);
      setValue("categories", inputData?.categories);
      setValue("image", inputData?.file);
      setValue("realtimeusers", inputData?.realtimeusers);
      setValue("totalusers", inputData?.totalusers);
      setValue("unitedstateusers", inputData?.unitedstateusers);
      setValue("argentinausers", inputData?.argentinausers);
      setValue("spainusers", inputData?.spainusers);
      setValue("columbiausers", inputData?.columbiausers);
      setValue("chinausers", inputData?.chinausers);
      setValue("germanyusers", inputData?.germanyusers);
    }
  }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setInputData({
          ...inputData,
          image: e.target.result,
          file: event.target.files[0],
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  async function upload(images) {
    const newImages = Object.values(images).map(async (value) => {
      try {
        const result = await imageUpload(value);
        return result;
      } catch (error) {
        throw error;
      }
    });

    return Promise.all(newImages);
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (params.productId) {
        if (data.file) {
          const files = await upload(data.file);
          Promise.all(files)
            .then(async (result) => {
              data.file = result?.map((item) => item.url);

              await setDoc(doc(fireDB, "products", params.productId), data);
              setLoading(false);
              navigate("/admin/product");
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          data.file = data.image;
          await setDoc(doc(fireDB, "products", params.productId), data);
          setLoading(false);
          navigate("/admin/product");
        }
      } else {
        if (data.file) {
          let files = await upload(data.file);
          Promise.all(files)
            .then(async (result) => {
              data.file = result?.map((item) => item.url);
              await addDoc(collection(fireDB, "products"), data);
              setLoading(false);
              navigate("/admin/product");
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          await addDoc(collection(fireDB, "products"), data);
          setLoading(false);
          navigate("/admin/product");
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const handleDelete = async (docId) => {
    const delRef = doc(fireDB, "products", params?.productId);
    await updateDoc(delRef, {
      file: arrayRemove(docId),
    });

    handleProduct();
  };
  const loader = (
    <div className="loading">
      <div className="spinner">
        <div
          className="spinner-border text-light bg-dark"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="contact-us">
        <div className="order-banner">
          {loading ? loader : null}
          <div className="container-sm">
            <div className="grid" data-gap="15px">
              <h1>Add a Product</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} ref={form}>
              <div className="grid" data-gap="40">
                <div className="grid" data-gap="80">
                  <div className="row gy-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter product name"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          type="text"
                          rows={4}
                          className="form-control form-controler"
                          placeholder="Enter product description"
                          {...register("description", { required: true })}
                        />
                      </div>
                    </div>
                    {categories ? (
                      <div className="col-12">
                        <Controller
                          render={({ field }) => (
                            <Select
                              options={categories}
                              getOptionLabel={(cate) => cate?.name}
                              getOptionValue={(cate) => cate?.name}
                              isMulti
                              {...field}
                              styles={colorStyles}
                            />
                          )}
                          name="categories"
                          control={control}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-8 col-12">
                      <div className="form-group">
                        <label>Cost Price (€)</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter Product cost price"
                          {...register("costprice")}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-group">
                        <label> Sale Price (€)</label>
                        <input
                          className="form-control form-controler"
                          placeholder="Enter Product Sale Price"
                          {...register("saleprice")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Product Profit (€)</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter Product Profit"
                          {...register("profit")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Margin (%)</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter margin"
                          {...register("margin")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Vendor</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total Vendor"
                          {...register("vendor")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Store</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total Store"
                          {...register("store")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Suppliers</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total Suppliers"
                          {...register("suppliers")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Sale (2.8K, 1.1M)</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total sale"
                          {...register("sale")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Total Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total users"
                          {...register("totalusers")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>RealTime Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter real users"
                          {...register("realtimeusers")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>UnitedState Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total users"
                          {...register("unitedstateusers")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Argentina Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total users"
                          {...register("argentinausers")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Spain Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total users"
                          {...register("spainusers")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Columbia Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total users"
                          {...register("columbiausers")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>China Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total users"
                          {...register("chinausers")}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Germany Users</label>
                        <input
                          type="text"
                          className="form-control form-controler"
                          placeholder="Enter total users"
                          {...register("germanyusers")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex ">
                      {watch("image") &&
                        getValues("image")?.map((item) => (
                          <div
                            style={{
                              position: "relative",
                              margin: "10px",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 9999,
                                top: 10,
                                left: 10,
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(item)}
                            >
                              <AiOutlineCloseCircle color="#1A242C" size={24} />
                            </div>
                            <div>
                              <img src={item} width="150" height="150" />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div
                        className="input-group mb-3"
                        style={{
                          background: "rgba(255, 255, 255, 0.1)",
                          border: "1px dashed rgba(255, 255, 255, 0.4)",
                          borderRadius: "8px",
                          padding: "16px",
                          gap: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100px",
                        }}
                      >
                        <input
                          type="file"
                          id="inputGroupFile02"
                          // hidden
                          multiple
                          {...register("file")}
                        />
                        <label
                          htmlFor="inputGroupFile02"
                          style={{ fontSize: "14px" }}
                        >
                          {watch("file") ? (
                            Object.values(getValues("file"))?.map((item) => (
                              <p>{item.name}</p>
                            ))
                          ) : (
                            <p>Upload your photo / document here</p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-holder d-flex justify-content-center">
                  <Button
                    Text={"Save"}
                    className={"btn-primary"}
                    URL={"arrow-right"}
                    type="submit"
                    disabled={!!loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Product;
const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    borderRadius: "20px",
    border: "2px solid #fff",
    padding: 4,
    boxShadow: "none",
    "&:hover": { borderColor: "#ccc" },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? data.color : "#fff",
      color: isSelected ? "#fff" : "#333",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": { backgroundColor: isSelected ? data.color : "#F4F4F4" },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color,
      color: "#1A232C",
      border: "1px solid #fff",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#fff",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#fff",
    ":hover": {
      backgroundColor: "#ccc",
      color: "#fff",
    },
  }),
};
