import React, { useState } from "react";
import Slider from "react-slick";
import { useTranslation, Trans } from "react-i18next";

import { NextArrow, PrevArrow } from "../arrows";
const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (current) => setCurrentSlide(current),
  };

  return (
    <React.Fragment>
      <div className="testimonial-section py-120">
        <div className="container">
          <Slider {...settings}>
            <div>
              <p>
                <span>Thomas Garufi</span> | Founder 
              </p>
              <h2>
              <Trans component={{ br: <br /> }}>Testimonial1</Trans>
              </h2>
            </div>
            <div>
              <p>
                <span>Sunny Rizki</span> | Consultant Manager Saphire Residence
              </p>
              <h2>
              <Trans component={{ br: <br /> }}>Testimonial2</Trans>
              </h2>
            </div>
          </Slider>
          <div className="total-slide">
            <p>{currentSlide + 1}/2</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonial;
