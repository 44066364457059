import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    document.body.classList.add("active-header");
    return function cleanup() {
      document.body.classList.remove("active-header");
    };
  }, []);
  return (
    <div className="container my-5">
      <div className="row">
        <div className="my-5 py-5">
          <p className="fs-3 fw-bolder">TERMINI &amp; CONDIZIONI</p>
          <p>
            <br />
            <li>
  <h6 className="fw-bold fs-5 lh-lg">1. Ambito di applicazione. Definizioni</h6>
  <p>
    <br />
  </p>
  <h6>
    Le presenti Condizioni Generali (“CG”) regolano i servizi offerti da SPS Fulfillment S.r.l.s., società 
    di diritto italiano, con sede legale in Milano, Via Borgonuovo 9, iscritta al Registro delle 
    Imprese di Milano al n. di codice fiscale e P.I. 12683190966 (“SPS”).
  </h6>
  <p>
    <br />
  </p>
  <h6>
    I rapporti tra SPS e il Committente (come di seguito definito) sono disciplinati dalle presenti 
    CG e dall’Ordine, come confermato da SPS. Il Committente è portato a conoscenza delle 
    presenti CG mediante comunicazione diretta delle medesime via email oppure all’atto 
    dell’inoltro dell’Ordine online; in entrambi i casi, con l’invio/inoltro dell’Ordine o con il 
    pagamento del relativo importo il Committente esprime e dichiara l’avvenuta lettura, 
    comprensione e accettazione delle CG.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    Oltre a quanto previsto in altre disposizioni delle presenti CG, i termini nel seguito 
    indicati hanno il significato in appresso precisato.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    “Clienti” sono i soggetti, persone fisiche o giuridiche, consumatori e non, cui il Committente 
    vende i Prodotti;
  </h6>
  <p>
    <br />
  </p>
  <h6>
    “Committente” è il soggetto che conferisce a SPS l’incarico per lo svolgimento di uno o più 
    Servizi;
  </h6>
  <p>
    <br />
  </p>
  <h6>
    “Ordine” è l’ordine mediante il quale il Committente richiede a SPS la prestazione di uno o più 
    Servizi, inviato a SPS mediante posta elettronica all’indirizzo di cui al successivo Art. 12, 
    oppure inoltrato mediante compilazione e invio dell’apposito modello online presente sul sito 
    di SPS www.spsfulfillment.com
  </h6>
  <p>
    <br />
  </p>
  <h6>
    “Prodotti” sono gli articoli acquistati da SPS, a fronte del ricevimento della provvista 
    necessaria, in nome proprio ma per conto del Committente, e oggetto di successiva vendita 
    da parte di quest’ultimo ai propri Clienti.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">2. Servizi</h6>
  <p>
    <br />
  </p>
  <h6>
    2.1 SPS presta, previa accettazione dell’Ordine e ricevimento dei corrispettivi di cui all’Art. 7, 
    le seguenti attività (i “Servizi” e, singolarmente, un “Servizio”):
  </h6>
  <p>
    <br />
  </p>
  <h6>
    (i) ricerca e selezione online dei Prodotti di volta in volta indicati dal Committente, e 
    conseguente individuazione di uno o più fornitori e delle relative condizioni per 
    l’acquisto/approvvigionamento di tali prodotti (“Servizio di Ricerca”);
  </h6>
  <p>
    <br />
  </p>
  <h6>
    (ii) acquisto online di tali prodotti, in nome proprio ma per conto del Committente, a fronte della 
    previa a) accettazione delle condizioni di fornitura e b) messa a disposizione, da parte del 
    medesimo, dei fondi necessari per il pagamento del prezzo (“Servizio di Acquisto”);    
  </h6>
  <p>
    <br />
  </p>
  <h6>
    (iii) stoccaggio temporaneo dei Prodotti, una volta consegnati dal fornitore (“Servizio di 
    Stoccaggio”);
  </h6>
  <p>
    <br />
  </p>
  <h6>
    (iv) imballaggio dei Prodotti e organizzazione, tramite spedizionieri/corrieri, della consegna 
    dei medesimi ai Clienti del Committente (“Servizio di Imballaggio e Consegna”);
  </h6>
  <p>
    <br />
  </p>
  <h6>
    (v) supporto alla individuazione di potenziali acquirenti, in via occasionale e su espressa 
    richiesta del Committente che SPS si riserva di accettare o meno, con vendita diretta dei 
    Prodotti da SPS ai Clienti, in nome proprio ma per conto del Committente (“Servizio di 
    Promozione e Vendita”).
  </h6>
  <p>
    <br />
  </p>
  <h6>
    2.2 Il Committente dichiara e garantisce che i Prodotti e tutti i prodotti indicati dal medesimo 
    Committente, nonché la loro vendita, promozione, distribuzione, commercializzazione e ogni 
    altra attività ad essi relativa, non sono (i) sottoposti a divieti, limitazioni o misure restrittive, di 
    qualsivoglia tipo, ai sensi delle norme italiane e/o dell’Unione Europea e/o di Stati terzi rilevanti 
    e/o sovranazionali, (ii) illegali, o discriminatori, (iii) contrari all’ordine pubblico o al buon 
    costume, (iv) pericolosi per i minori e/o per persone affette da disabilità, lesivi della dignità o 
    diritti delle persone o animali; (v) in violazione di diritti di terzi, inclusi in particolare diritti di 
    proprietà intellettuale; (vi) pericolosi, non sicuri o non compatibili per/con l’ambiente.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    2.3 Senza pregiudizio degli obblighi di garanzia e indennizzo del Committente di cui ai 
    successivi Artt. 3.2, 3.3 e 5.6 relativamente ai Clienti (inclusi i Clienti Consumatori), nonché 
    dei diritti di SPS ai sensi dell’Art. 9, il Committente si obbliga a indennizzare SPS per ogni 
    perdita, danno, spesa o altra conseguenza pregiudizievole (incluso sanzioni e/o ordini di 
    pubbliche autorità) derivante da o attribuibile a, ogni possibile azione, eccezione, 
    contestazione o pretesa, giudiziale o extragiudiziale, che fosse da qualsivoglia terzo, incluse 
    pubbliche autorità, avanzata e/o pretesa e/o imposta per cause dipendenti e/o connesse ai 
    Servizi, in particolare al Servizio di Promozione, e/o alla vendita dei Prodotti, e/o ai Prodotti 
    medesimi (i) quali, a titolo esemplificativo ma non esaustivo, responsabilità da prodotto 
    difettoso, non sicuro o non conforme, mancanza o difetto di tracciabilità dei Prodotti, difettosità 
    degli imballaggi, pubblicità ingannevole, violazione di norme sulla protezione dei dati 
    personali, violazione di misure restrittive nazionali, UE, sovranazionali o di Stati Terzi, 
    violazione di norme doganali e/o relative alla immissione in commercio o circolazione di beni, 
    mancata compliance con norme tecniche e/o istruzioni del produttore, violazione dei diritti di 
    terzi, inclusi diritti di proprietà intellettuale, danni a cose o persone in generale, inclusi danni 
    morali; o (ii) dovute a incompletezza, non veridicità e/o omissioni delle dichiarazioni del 
    Committente ai sensi dell’Art. 2.2.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">3. Esclusione di responsabilità e Obblighi di indennizzo</h6>
  <p>
    <br />
  </p>
  <h6>
    3.1 Le CG e gli Ordini accettati da SPS, regolano i rapporti contrattuali tra il Committente e 
    SPS, in qualità di mandataria e prestatrice di servizi al Committente.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    3.2 Il Servizio di Acquisto, il Servizio di Promozione e [Vendita] e tutti gli altri Servizi sono resi 
    da SPS al Committente e non comportano rapporti di alcun tipo di SPS con i Clienti del 
    Committente. Il Committente è la sola ed esclusiva controparte contrattuale dei Clienti e 
    assume in via esclusiva ed integrale tutti gli obblighi e responsabilità, contrattuali ed 
    extracontrattuali, nei confronti dei medesimi Clienti.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    3.3 Conseguentemente, senza pregiudizio di norme imperative, è esclusa qualsivoglia 
    responsabilità, obbligazione o garanzia di SPS nei confronti dei Clienti e il Committente si 
    obbliga a indennizzare SPS per ogni perdita danno spesa o altra conseguenza pregiudizievole 
    (incluso sanzioni e/o ordini della pubblica autorità) derivante da o attribuibile a ogni possibile 
    azione, eccezione, contestazione o pretesa, giudiziale o extragiudiziale, che fosse da 
    qualsivoglia Cliente [del Committente], incluso un Cliente consumatore, e/o pubblica autorità 
    avanzata per cause dipendenti e/o connesse ai Servizi, in particolare al Servizio di Promozione, 
    e/o alla vendita dei Prodotti, quali, a titolo esemplificativo ma non esaustivo, per 
    mancata vendita, ritardo nella consegna, mancata disponibilità dei Prodotti, responsabilità da 
    prodotto difettoso, non sicuro o non conforme, mancanza o difetto di tracciabilità dei Prodotti, 
    difettosità degli imballaggi, perdite o danni occorsi nel trasporto, pubblicità ingannevole, 
    violazione della privacy, violazione dei diritti di terzi, inclusi diritti di proprietà intellettuale, danni 
    a cose o persone in generale, inclusi danni morali.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    3.4 SPS non risponde verso il Committente dell’adempimento dei Clienti e, in generale, delle 
    obbligazioni assunte dalle persone con le quali ha contrattato per conto del Committente.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    3.5 SPS non riscuote il prezzo dei Prodotti dai Clienti, che pagano i relativi corrispettivi 
    direttamente al Committente, ad eccezione dei casi in cui i) il Committente richieda a SPS di     eseguire la vendita per conto del Committente ma in nome proprio, ii) il pagamento del Cliente
    avvenga a mezzo contrassegno. I corrispettivi dei Prodotti pagati dai Clienti a SPS nel caso
    di cui al paragrafo 3.5 lett. i) saranno da questa versati al Committente, previa deduzione delle
    spese di imballaggio, promozione, spedizione e assicurazione sostenute da SPS, e senza
    pregiudizio dei diritti di quest’ultima ai sensi dell’art. 1721 c.c., nel caso di cui al paragrafo 3.5
    lett. ii) saranno da questa versati al Committente con successiva fatturazione di SPS per i
    Servizi resi e le spese sostenute.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    3.6 Il Committente provvede alla stipulazione delle assicurazioni obbligatorie o volontarie
    relative ai Prodotti, al loro trasporto, imballaggio, deposito, consegna, danni a terzi, anche a
    favore di SPS, dandone prontamente evidenza documentale a SPS, se richiesto.
  </h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold fs-5 lh-lg">4. Conferimento degli incarichi e svolgimento dei Servizi di Ricerca e Acquisto</h6>
  <p>
    <br />
  </p>
  <h6>
    4.1 I singoli Servizi di Ricerca e Acquisto vengono commissionati dal Committente a SPS
    mediante ordine (“Ordine”), da comunicarsi mediante posta elettronica all’indirizzo di cui al
    successivo Art. 12, oppure inoltrato mediante compilazione e invio dell’apposito modello
    online presente sul sito di SPS. SPS si riserva di non accettare l’Ordine, o di chiederne
    modificazioni, dandone comunicazione scritta al Committente.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    4.2 SPS sottoporrà al Committente l’opportunità di acquisto individuata, completa dei dati
    relativi ai Prodotti, al fornitore, ai prezzi, IVA se applicabile (la “Quotazione”). Entro 10 giorni
    dal ricevimento della Quotazione, il Committente comunicherà a SPS se intende accettarla,
    in caso positivo versando contestualmente a SPS, mediante bonifico bancario immediato sul
    conto corrente intestato a SPS indicato nella Quotazione, l’importo necessario per procedere
    all’acquisto dei Prodotti, per la quantità confermata dal Committente. Resta inteso che la
    scelta del fornitore ricade sul Committente il quale sarà libero di accettarla o meno
    assumendosi ogni rischio in proposito con esclusione di qualsivoglia responsabilità per culpa
    in eligendo in capo a SPS.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    4.3 Resta inteso che SPS non potrà prestare il Servizio di Acquisto, e pertanto non procederà
    all’acquisto dei prodotti sino al ricevimento del bonifico di cui al precedente paragrafo, bonifico
    che dovrà recare la causale riferita allo specifico acquisto dei prodotti per conto del
    Committente stesso, in conformità alla Quotazione.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    4.4 È esclusa qualsivoglia responsabilità di SPS, nei confronti del Committente e/o di Clienti
    o altri terzi, in caso di omesso acquisto dei prodotti per mancato ricevimento dei fondi per
    procedere all’acquisto nel termine di cui al precedente paragrafo 4.2.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    4.5 È parimenti esclusa qualsiasi responsabilità di SPS qualora, nelle more dei termini di
    accettazione della Quotazione da parte del Committente, venga meno la disponibilità dei
    prodotti individuata nella Quotazione, o si verifichi un cambiamento delle condizioni della
    fornitura. SPS darà comunicazione di tali circostanze al Committente e, sulla base delle
    istruzioni di quest’ultima (da comunicarsi a SPS entro le 24 ore successive) procederà
    all’acquisto oppure restituirà i fondi relativi alla Quotazione; è escluso il pagamento di interessi
    da SPS al Committente per le somme da questa anticipate.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    4.6 SPS darà comunicazione al Committente dell’avvenuta esecuzione dell’Ordine (e relativo
    acquisto dei Prodotti).
  </h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold fs-5 lh-lg">5. Servizi di Stoccaggio e Imballaggio</h6>
  <p>
    <br />
  </p>
  <h6>
    5.1 Qualora richiesto dal Committente nel relativo Ordine, i Prodotti acquistati potranno
    essere consegnati dal fornitore a SPS, all’indirizzo da questa comunicato.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    5.2 In tali casi SPS darà tempestiva comunicazione al Committente della consegna dei
    Prodotti e provvederà, per conto, a spese e su istruzioni del Committente al loro
    confezionamento e alla consegna, con le modalità e seguendo le istruzioni specificate dal
    Committente nel relativo Ordine, ai Clienti (e relativi recapiti) indicati dal Committente, a mezzo
    corriere o altro mezzo indicato dal Committente. Gli importi delle spese di spedizione, di
    imballaggio e assicurazione dovranno essere settimanalmente rimborsati a SPS, mediante
    bonifico bancario su conto corrente a questa intestato e comunicato al Committente. Nei casi
    in cui SPS venda ai Clienti per conto del Committente ma in nome proprio, tratterrà dalle
    somme riscosse dai Clienti l’importo delle spese di spedizione, imballaggio e assicurazione
    sostenute, ai sensi del precedente Art. 3.5.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    5.3 I termini di consegna dei Prodotti sono quelli indicativi comunicati nella Quotazione. È
    esclusa qualsivoglia responsabilità di SPS per mancata consegna e/o per eventuali ritardi
    nella consegna da parte del fornitore e/o di altri soggetti, inclusi gli incaricati del servizio di
    spedizione e consegna dei Prodotti ai Clienti.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    5.4 Il Committente si obbliga a fornire le istruzioni per la spedizione dei Prodotti entro e
    non oltre il termine indicato nella Quotazione (il Termine”) o, in alternativa, a ritirarli dai locali
    di SPS entro la medesima data. In assenza di un Termine indicato nella Quotazione esso si
    intenderà pari a 30 giorni. Qualora il Committente non provveda al ritiro entro il Termine, sarà
    addebitata una penale per il ritardo pari a 10,00 Euro al giorno fatto salvo l’eventuale maggior
    danno. Decorsi 60 giorni dal Termine SPS avrà facoltà di spedire i Prodotti giacenti nei locali
    di SPS al Committente a rischio e spese di quest’ultimo. Qualora il Committente dovesse
    rifiutare la consegna dei Prodotti SPS avrà facoltà di i) smaltirli a spese del Committente o ii)
    acquistarli a 1/10 (un-decimo) del prezzo applicato al Committente al netto dell’IVA.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    5.5 Per chiarezza le Parti ribadiscono che la compravendita dei Prodotti intercorre tra il
    Committente e i Clienti, con totale estraneità di SPS a tale rapporto, e che pertanto anche al
    Servizio di Promozione si applica quanto disposto ai Paragrafi 3.2 e 3.3. che precedono.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    Le disposizioni di cui ai predetti Paragrafi 3.2. e 3.3 trovano applicazione, mutatis mutandis,
    anche alle fattispecie di vendita da parte di SPS in nome proprio e per conto del Committente,
    fatte salve le disposizioni inderogabili di legge a favore del Cliente consumatore.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">6. Servizio di Promozione</h6>
  <p>
    <br />
  </p>
  <h6>
    6.1 Qualora richiesto dal Committente, SPS potrà collaborare con quest’ultimo, con
    obbligazione di mezzi e non di risultato, per la individuazione di potenziali Clienti con
    riferimento a specifici Prodotti.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    6.2 A prescindere dal conferimento o meno dell’incarico di individuare potenziali Clienti, è
    esclusa qualsivoglia responsabilità di SPS per la mancata collocazione/vendita di Prodotti a
    Clienti. Qualora il Committente abbia richiesto il Servizio di Stoccaggio, si applicano le
    disposizioni di cui al precedente Art. 5.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">7. Corrispettivo. Pagamenti</h6>
  <p>
    <br />
  </p>
  <h6>
    7.1 Quale corrispettivo per la prestazione dei Servizi, il Committente corrisponderà a SPS le
    gli importi indicati nella Quotazione.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    7.2 La liquidazione dei compensi spettanti a SPS avverrà in forma anticipata. Per quanto
    riguarda il costo con periodicità settimanale, ciascuna settimana relativamente ai Servizi
    prestati nel mese precedente. Il pagamento dovrà essere effettuato entro 15 (quindici) giorni
    data fattura (pro forma).
  </h6>
  <p>
    <br />
  </p>
  <h6>
    7.3 SPS è dispensata dall’obbligo di rendiconto.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    7.4 Per i crediti nascenti dalla prestazione dei Servizi SPS ha diritto di ritenzione sui Prodotti
    e di compensazione con gli importi anticipati dal Committente per l’acquisto di Prodotti e spese
    di spedizione.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    7.5 Ai sensi dell’art. 1721 c.c., SPS in qualità di mandatario ha diritto di soddisfarsi sui crediti
    pecuniari sorti dai rapporti del Committente con i Clienti, con precedenza sulla medesima e
    sui creditori di questa.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">8. Forza Maggiore</h6>
  <p>
    <br />
  </p>
  <h6>
    8.1 Nessuna parte sarà responsabile per ritardo, inesatta o mancata esecuzione di obblighi
    se il ritardo o la mancata o l’inesatta esecuzione sono causati da un evento di forza maggiore.
    Caso di forza maggiore è qualsiasi evento o circostanza imprevisto al di là di qualsiasi
    ragionevole controllo delle parti incluso, a titolo esemplificativo ma non esaustivo, terremoti,
    calamità naturali, inondazioni, eventi avversi metereologici e/o idrogeologici, eventi pandemici
    e/o misure restrittive adottate in conseguenza degli stessi da autorità nazionali e locali,
    scioperi, serrate, guerre, scontri, insurrezioni, interruzioni o restrizioni di traffico sulla linea
    ferroviaria o automobilistica, interruzioni della fornitura di energia elettrica, provvedimenti
    giudiziari o delle pubbliche autorità statali, regionali o locali, dell’Unione Europea o altre
    organizzazioni internazionali, incluse a titolo esemplificativo e non esaustivo misure restrittive
    e sanzioni inter alia restrizioni all’importazione ed esportazione, embarghi, sanzioni finanziarie.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">9. Rifiuto Ordini, Sospensione, Clausola Risolutiva Espressa</h6>
  <p>
    <br />
  </p>
  <h6>
    9.1 SPS si riserva di non accettare un Ordine, oppure di risolverlo, ai sensi e per gli effetti
    dell’art. 1456 c.c., senza che occorra al riguardo alcun atto di costituzione in mora:
  </h6>
  <p>
    <br />
  </p>
  <h6>
    a) qualora l’altra Parte richieda di essere ammessa o venga ammessa a una
    procedura concorsuale o versi in condizioni patrimoniali tali da non garantire
    l’esatto adempimento delle obbligazioni di cui all’Ordine o degli eventuali obblighi
    risarcitori da esso derivanti; fatta salva l’applicazione delle norme inderogabili di
    legge in materia;
  </h6>
  <p>
    <br />
  </p>
  <h6>
    b) in caso di mancato o ritardato pagamento da parte del Cliente dei corrispettivi
    e/o di altri importi dovuti nei termini di cui al precedente Art. 7 per un ammontare
    complessivamente superiore a Euro [200], oppure di 2 (due) fatture consecutive,
    a prescindere dal loro importo e fermo restando ogni altro rimedio di legge;
  </h6>
  <p>
    <br />
  </p>
  <h6>
    c) violazione da parte del Committente delle dichiarazioni e degli obblighi previsti
    all’Art. 2.2.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    9.2 Salvo ogni altro rimedio previsto dalla legge e dal presente Contratto, in caso di
    mancato pagamento da parte del Committente dei corrispettivi dovuti, nonché in caso di
    violazione da parte del Committente delle dichiarazioni e degli obblighi di cui all’Art. 2.2, SPS
    avrà diritto a sospendere il Servizio, ai sensi dell’art. 1460 c.c.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">10. Protezione dei Dati Personali</h6>
  <p>
    <br />
  </p>
  <h6>
    Il modulo per la privacy si trova sul sito di SPS www.spsfulfillment.com. Il cliente è
    tenuto a verificare il modulo prima di completare l’acquisto.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">11. Legge Regolatrice e Foro Competente</h6>
  <p>
    <br />
  </p>
  <h6>
    11.1 Le presenti CG, gli Ordini, i Servizi, incluso per gli aspetti relativi ai rapporti con i Clienti,
    sono regolati dalla legge italiana, con esclusione delle norme di conflitto. E’ esclusa
    l’applicazione della Convenzione di Vienna sulla vendita internazionale di beni mobili.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    11.2 Per ogni controversia relativa all’interpretazione o all’esecuzione delle presenti CG, degli
    Ordini e/o dei relativi singoli Servizi, incluso relativamente ai rapporti con i Clienti, è
    esclusivamente competente il Foro di Milano.
  </h6>
  <p>
    <br />
  </p>
</li>
<li>
  <h6 className="fw-bold fs-5 lh-lg">12. Comunicazioni</h6>
  <p>
    <br />
  </p>
  <h6>
    12.1 Qualsiasi avviso, notifica, richiesta, domanda o comunicazione di una parte all’altra dovrà
    essere redatta in forma scritta e inviata mediante posta elettronica certificata (PEC) come
    segue:
  </h6>
  <p>
    <br />
  </p>
  <h6>
    Se a SPS:
  </h6>
  <p>
    <br />
  </p>
  <h6>
    PEC: [spsfulfillment@pec.it]
  </h6>
  <p>
    <br />
  </p>
  <h6>
    Se alla Committente:
  </h6>
  <p>
    <br />
  </p>
  <h6>
    PEC: pec fornita dal Committente
  </h6>
  <p>
    <br />
  </p>
  <h6>
    Ciascuna Parte dovrà informare l’altra Parte in caso di variazione dei suddetti indirizzi
    PEC, specificando la data di decorrenza del nuovo indirizzo.
  </h6>
  <p>
    <br />
  </p>
  <h6>
    12.2 Per le comunicazioni di carattere operativo inerenti gli Ordini e svolgimento dei Servizi,
    esse dovranno effettuarsi in forma scritta, via posta elettronica agli indirizzi dei referenti indicati
    nell’Ordine come confermato da SPS.
  </h6>
  <p>
    <br />
  </p>
</li>
 </p>  
        </div>{" "}
      </div>
    </div>
  );
};

export default Terms;
