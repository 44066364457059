import React from "react";

import { ReactSVG } from "react-svg";
import Button from "../components/Common/Button";
import { useTranslation, Trans } from "react-i18next";

const ProductResearch = () => {
  return (
    <React.Fragment>
      <div className="product-research">
        <div className="research-banner">
          <h1><Trans component={{ br: <br /> }}>Researchtitle</Trans></h1>
          <p>
          <Trans component={{ br: <br /> }}>Researchsubtitle</Trans>
          </p>
        </div>

        <div className="banner-card">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-12 my-3">
                <div className="card card-hover">
                  <div className="card-body grid" data-gap="15">
                    <div className="icon-rounded bg-light">
                      <ReactSVG src="/assets/icons/box-resarch.svg" />
                    </div>
                    <h3><Trans component={{ br: <br /> }}>titlefirstcard</Trans></h3>
                    <p>
                    <Trans component={{ br: <br /> }}>subtitlefirstcard</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 my-3">
                <div className="card card-hover">
                  <div className="card-body grid" data-gap="15">
                    <div className="icon-rounded bg-light">
                      <ReactSVG src="/assets/icons/lamp-on.svg" />
                    </div>
                    <h3><Trans component={{ br: <br /> }}>titlesecondcard</Trans></h3>
                    <p>
                    <Trans component={{ br: <br /> }}>subtitlesecondcard</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 my-3">
                <div className="card card-hover">
                  <div className="card-body grid" data-gap="15">
                    <div className="icon-rounded bg-light">
                      <ReactSVG src="/assets/icons/people.svg" />
                    </div>
                    <h3><Trans component={{ br: <br /> }}>titlethirdcard</Trans></h3>
                    <p>
                    <Trans component={{ br: <br /> }}>subtitlethirdcard</Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container">
            <div className="row my-5">
              <div className="col-12 mb-5">
                <h2 className="section-title text-center">
                <Trans component={{ br: <br /> }}>secondtitleresearch</Trans>
                </h2>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/chart-v2.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold"><Trans component={{ br: <br /> }}>firstbenefittitle</Trans></h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>firstbenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-0 col-md-7"></div>
            </div>
            {/* 2nd product */}
            <div className="row my-2">
              <div className="col-0 col-md-7"></div>
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/note.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold">
                    <Trans component={{ br: <br /> }}>secondbenefittitle</Trans>
                    </h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>secondbenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* 3nd product */}
            <div className="row my-2">
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/video.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold">
                    <Trans component={{ br: <br /> }}>thirdbenefittitle</Trans>
                    </h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>thirdbenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-0 col-md-7"></div>
            </div>
            {/* 4th */}
            <div className="row my-2">
              <div className="col-0 col-md-7"></div>
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/facebook.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold"><Trans component={{ br: <br /> }}>fourthbenefittitle</Trans></h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>fourthbenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* 5th */}
            <div className="row my-2">
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/star.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold"><Trans component={{ br: <br /> }}>fivebenefittitle</Trans></h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>fivebenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-0 col-md-7"></div>
            </div>
            {/* 6th */}
            <div className="row my-2">
              <div className="col-0 col-md-7"></div>
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/user-square.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold">
                    <Trans component={{ br: <br /> }}>sixbenefittitle</Trans>
                    </h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>sixbenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* 7th */}
            <div className="row my-2">
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/lamp-charge.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold">
                    <Trans component={{ br: <br /> }}>sevenbenefittitle</Trans>
                    </h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>sevenbenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-0 col-md-7"></div>
            </div>
            {/* 8th */}
            <div className="row my-2">
              <div className="col-0 col-md-7"></div>
              <div className="col-12 col-md-5">
                <div className="auto-fr align-items-start" data-gap="25">
                  <div className="icon-rounded bg-light">
                    <ReactSVG src="/assets/icons/clock.svg" />
                  </div>
                  <div className="grid" data-gap="10">
                    <h3 className="fw-semibold"><Trans component={{ br: <br /> }}>eightbenefittitle</Trans></h3>
                    <p className="lh-lg">
                    <Trans component={{ br: <br /> }}>eightbenefitsubtitle</Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-12 mb-5">
                <div className="button-holder">
                  <a
                    href="https://buy.stripe.com/aEUg1OgT63SG8F23cc"
                    target="_blank"
                  >
                    <Button
                      Text={<Trans component={{ br: <br /> }}>buttonresearch</Trans>}
                      className="btn-primary mx-auto"
                      URL={"arrow-right"}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductResearch;
