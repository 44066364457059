import React from "react";
import { useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { useTranslation, Trans } from "react-i18next";


const ShippingCardItem = ({ image, background, handleClick, color, Items }) => {
  let [slider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  function next() {
    slider.slickNext();
  }
  function previous() {
    slider.slickPrev();
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    afterChange: (current) => {
      // setPrice(Items[currentSlide]?.price);
      handleClick(Items[current]?.price);
      setCurrentSlide(current);
    },
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };

  return (
    <React.Fragment>
      <div
        className="card rounded-top"
        style={{
          width: "18rem",
          boxShadow: "16px 46px 24px rgba(62, 76, 92, 0.2)",
          border: `1px solid ${color}`,
        }}
      >
        <Slider {...settings} ref={(c) => (slider = c)}>
          {Items?.map((item) => (
            <div key={item.id}>
              <div className="">
                <div style={{ textAlign: "center", background: color }}>
                  <LazyLoadImage
                    src={image}
                    style={{
                      display: "inline-block",
                      paddingTop: "10px",
                    }}
                  />
                </div>
                <div className="desc px-2 py-3">
                  <div className="grid" data-gap="15">
                    <div className="grid" data-gap="10">
                      <div
                        className="h-list justify-content-between"
                        data-gap="5"
                      >
                        <p><Trans component={{ br: <br /> }}>Itemsize</Trans></p>
                        <p>{item.size}</p>
                      </div>
                      <div
                        className="h-list justify-content-between"
                        data-gap="5"
                      >
                        <p><Trans component={{ br: <br /> }}>Itemweight</Trans></p>
                        <p>{item.weight}</p>
                      </div>
                    </div>
                    <div
                      className="h-list justify-content-between"
                      data-gap="5"
                    >
                      <h2 className="fs-5 fw-semibold">Shipping Cost</h2>
                      <p>
                        <span className="fw-semibold fs-6">€{item.price}</span>{" "}
                        <Trans component={{ br: <br /> }}>Perunit</Trans>
                      </p>
                    </div>
                    <div className="card-handles"></div>
                    <div className="divider"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div>
            <div className="item">
              <div style={{ textAlign: "center" }}>
                <LazyLoadImage
                  src="/assets/image/order-item-1.png"
                  style={{ display: "inline-block" }}
                />
              </div>
              <div className="desc">
                <div className="grid" data-gap="15">
                  <div className="grid" data-gap="10">
                    <div
                      className="h-list justify-content-between"
                      data-gap="5"
                    >
                      <p>Small Size</p>
                      <p>6 x 1 x 2 in</p>
                    </div>
                    <div
                      className="h-list justify-content-between"
                      data-gap="5"
                    >
                      <p>Item Weight</p>
                      <p>1.5oz</p>
                    </div>
                  </div>
                  <div className="h-list justify-content-between" data-gap="5">
                    <h2 className="fs-5 fw-semibold">Shipping Cost</h2>
                    <p>
                      <span className="fw-semibold fs-6">$0.99</span> / per unit
                    </p>
                  </div>
                  <div className="card-handles"></div>
                  <div className="divider"></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="item">
              <div style={{ textAlign: "center" }}>
                <LazyLoadImage
                  src="/assets/image/order-item-1.png"
                  style={{ display: "inline-block" }}
                />
              </div>
              <div className="desc">
                <div className="grid" data-gap="15">
                  <div className="grid" data-gap="10">
                    <div
                      className="h-list justify-content-between"
                      data-gap="5"
                    >
                      <p>Small Size</p>
                      <p>3 x 2 x 4 in</p>
                    </div>
                    <div
                      className="h-list justify-content-between"
                      data-gap="5"
                    >
                      <p>Item Weight</p>
                      <p>2.5oz</p>
                    </div>
                  </div>
                  <div className="h-list justify-content-between" data-gap="5">
                    <h2 className="fs-5 fw-semibold">Shipping Cost</h2>
                    <p>
                      <span className="fw-semibold fs-6">$1.99</span> / per unit
                    </p>
                  </div>
                  <div className="card-handles"></div>
                  <div className="divider"></div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="item">
              <div style={{ textAlign: "center" }}>
                <LazyLoadImage
                  src="/assets/image/order-item-1.png"
                  style={{ display: "inline-block" }}
                />
              </div>
              <div className="desc">
                <div className="grid" data-gap="15">
                  <div className="grid" data-gap="10">
                    <div
                      className="h-list justify-content-between"
                      data-gap="5"
                    >
                      <p>Small Size</p>
                      <p>6 x 1 x 2 in</p>
                    </div>
                    <div
                      className="h-list justify-content-between"
                      data-gap="5"
                    >
                      <p>Item Weight</p>
                      <p>1.5oz</p>
                    </div>
                  </div>
                  <div className="h-list justify-content-between" data-gap="5">
                    <h2 className="fs-5 fw-semibold">Shipping Cost</h2>
                    <p>
                      <span className="fw-semibold fs-6">$0.99</span> / per unit
                    </p>
                    <div className="card-handles"></div>
                    <div className="divider"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Slider>

        <div
          className="justify-content-end text-right d-flex"
          style={{ position: "relative" }}
        >
          <button
            onClick={previous}
            style={{ border: "none", background: "transparent" }}
          >
            <BsChevronLeft />
          </button>
          <button
            onClick={next}
            style={{ border: "none", background: "transparent" }}
          >
            <BsChevronRight />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShippingCardItem;
