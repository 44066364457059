import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addDoc, doc, getDoc, setDoc, collection } from "firebase/firestore";
import Button from "../../components/Common/Button";
import { fireDB } from "../../../config/firebase";
import { useForm } from "react-hook-form";

const CreateCategory = () => {
  useEffect(() => {
    document.body.classList.add("active-header");
    return function cleanup() {
      document.body.classList.remove("active-header");
    };
  }, []);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if (params.categoryId) {
      handleCategory();
    }
  }, [params]);
  async function handleCategory() {
    const docRef = doc(fireDB, "categories", params.categoryId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let inputData = { ...docSnap.data(), id: docSnap.id };
      setValue("name", inputData?.name);
      setValue("status", inputData?.status);
    }
  }
  const onSubmit = async (data) => {
    setLoading(true);
    if (params.categoryId) {
      let doc = await setDoc(
        doc(fireDB, "categories", params.categoryId),
        data,
        { merge: true }
      );

      setLoading(false);
      navigate("/admin/category");
    } else {
      const docRef = await addDoc(collection(fireDB, "categories"), data);
      console.log("Document written with ID: ", docRef.id);

      setLoading(false);
      navigate("/admin/category");
    }
  };
  const loader = (
    <div className="loading">
      <div className="spinner">
        <div
          className="spinner-border text-light bg-dark"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div className="contact-us">
        <div className="contact-us-banner">
          {loading ? loader : null}
          <div className="container-sm">
            <div className="grid" data-gap="15px">
              <h1>Create Category</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid" data-gap="40">
                <div className="grid" data-gap="80">
                  <div className="row g-5">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("name", { required: true })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Status</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("status", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-holder  d-flex justify-content-center">
                  <Button
                    Text={"Create Category"}
                    className={"btn-primary"}
                    URL={"arrow-right"}
                    type="submit"
                    disabled={!!loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateCategory;
