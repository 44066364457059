import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

import Button from "./Button";

const TextFeed = ({ title, desc, image, button }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="text-feed">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="image">
                <LazyLoadImage src={image} />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="grid" data-gap="40">
                <div className="grid" data-gap="15">
                  <div className="title">{title}</div>
                  <div className="desc">{desc}</div>
                </div>
                {button && (
                  <div className="button-holder">
                    <Button
                      className={"btn-primary"}
                      Text={button}
                      URL={"arrow-right"}
                      onClick={() => navigate("/contact-us")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextFeed;
