import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import AboutBanner from "../components/AboutPage/AboutBanner";
import OurTeam from "../components/AboutPage/OurTeam";
import TextFeed from "../components/Common/TextFeed";
import { useTranslation, Trans } from "react-i18next";

const AboutUs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("active-header");
    return function cleanup() {
      document.body.classList.remove("active-header");
    };
  }, []);

  return (
    <React.Fragment>
      <div className="about-us">
        <div className="grid" data-gap="80">
          <AboutBanner />
          <div className="container">
            <div className="grid our-mission" data-gap="40">
              <div className="section-title"><Trans>Ourmissiontitle</Trans></div>
              <p className="simple-text">
                <Trans>aboutSummary</Trans>
              </p>
            </div>
          </div>
        </div>

        <OurTeam />
        <div className="about-us-feed py-120 px-5">
          <TextFeed
            title={
              <Trans>futureplantitle</Trans>
            }
            desc={
              <Trans>futureplantext</Trans>
            }
            button={<Trans>SendFeedbackbutton</Trans>}
            image={"/assets/image/about-us-feed.jpg"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
