import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    document.body.classList.add("active-header");
    return function cleanup() {
      document.body.classList.remove("active-header");
    };
  }, []);
  return (
    <div className="container my-5">
      <div className="row">
        <div className="my-5 py-5">
          <p className="fs-3 fw-bolder">Privacy Policy / Cookie Policy</p>
          <p>
            <br />
            <li>
  <h6 className="fw-bold">1. Introduzione</h6>
  <p>
    SPS Fulfillment S.r.l.s. (“SPS”, “noi” o “ci”) si impegna a proteggere la privacy dei nostri clienti e utenti del nostro sito web. La presente Privacy Policy descrive le modalità con cui raccogliamo, utilizziamo e condividiamo le informazioni personali.
  </p>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">2. Informazioni che raccogliamo</h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">2.1 Informazioni fornite dall'utente</h6>
  <p>
    Quando utilizzate i nostri servizi, potremmo raccogliere informazioni personali che ci fornite direttamente, come nome, indirizzo email, numero di telefono, indirizzo di spedizione e informazioni di pagamento.
  </p>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">2.2 Informazioni raccolte automaticamente</h6>
  <p>
    Durante la navigazione sul nostro sito, possiamo raccogliere automaticamente alcune informazioni tramite cookie, web beacon e altre tecnologie, inclusi l'indirizzo IP, il tipo di browser, il sistema operativo, le pagine visitate e il tempo trascorso sul sito.
  </p>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">3. Utilizzo delle informazioni</h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">3.1 Fornitura dei servizi</h6>
  <p>
    Utilizziamo le informazioni raccolte per fornire, gestire e migliorare i nostri servizi, processare gli ordini, rispondere alle richieste dei clienti e inviare comunicazioni relative ai nostri servizi.
  </p>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">3.2 Comunicazioni di marketing</h6>
  <p>
    Con il vostro consenso, possiamo utilizzare le vostre informazioni per inviare offerte promozionali e aggiornamenti su nuovi prodotti e servizi. Potete scegliere di non ricevere più tali comunicazioni in qualsiasi momento.
  </p>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">4. Condivisione delle informazioni</h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">4.1 Condivisione con terze parti</h6>
  <p>
    Non vendiamo, scambiamo o trasferiamo a terzi le vostre informazioni personali senza il vostro consenso, eccetto per quanto necessario a fornire i nostri servizi, rispettare obblighi legali o proteggere i nostri diritti.
  </p>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">4.2 Fornitori di servizi</h6>
  <p>
    Possiamo condividere le vostre informazioni con fornitori di servizi di terze parti che ci assistono nelle operazioni aziendali, come l'elaborazione dei pagamenti, la spedizione degli ordini e l'analisi dei dati.
  </p>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">5. Sicurezza delle informazioni</h6>
  <p>
    <br />
  </p>
  <h6>
    Adottiamo misure di sicurezza tecniche e organizzative per proteggere le informazioni personali contro accessi non autorizzati, alterazioni, divulgazioni o distruzioni. Tuttavia, nessun metodo di trasmissione su Internet o di archiviazione elettronica è completamente sicuro.
  </h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">6. I vostri diritti</h6>
  <p>
    <br />
  </p>
  <h6>
    Avete il diritto di accedere, correggere, aggiornare o richiedere la cancellazione delle vostre informazioni personali in nostro possesso. Potete esercitare questi diritti contattandoci utilizzando le informazioni di contatto fornite di seguito.
  </h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">7. Modifiche alla Privacy Policy</h6>
  <p>
    <br />
  </p>
  <h6>
    Ci riserviamo il diritto di aggiornare questa Privacy Policy in qualsiasi momento. Vi informeremo di eventuali modifiche pubblicando la nuova Privacy Policy sul nostro sito web. Vi invitiamo a consultare periodicamente questa pagina per rimanere informati sulle nostre pratiche relative alla privacy.
  </h6>
  <p>
    <br />
  </p>
  <h6 className="fw-bold">8. Contatti</h6>
  <p>
    <br />
  </p>
  <h6>
    Per qualsiasi domanda o richiesta riguardante la presente Privacy Policy o il trattamento delle vostre informazioni personali, potete contattarci a:
  </h6>
  <p>
    SPS Fulfillment S.r.l.s.<br />
    Via Borgonuovo 9, Milano, Italia<br />
    Email: info@spsfulfillment.com<br />
    PEC: spsfulfillment@pec.it
  </p>
  <p>
    <br />
  </p>
</li>






          </p>
        </div>{" "}
      </div>
    </div>
  );
};

export default Terms;
