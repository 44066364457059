import React from "react";
import { ReactSVG } from "react-svg";
const stats = [
  {
    price: "€34.19",
    name: "Cost Price",
    value: "costprice",
    sign: "€",
    icon: <ReactSVG src="/assets/icons/coin.svg" />,
  },
  {
    price: "€64.00",
    name: "Sale Price",
    value: "saleprice",
    sign: "€",
    icon: <ReactSVG src="/assets/icons/discount-circle.svg" />,
  },
  {
    price: "€16.41",
    name: "Profit",
    value: "profit",
    sign: "€",
    icon: <ReactSVG src="/assets/icons/tag-2.svg" />,
  },
  {
    price: "55.00%",
    name: "Profit Margin",
    value: "margin",
    sign: "%",
    icon: <ReactSVG src="/assets/icons/money-4.svg" />,
  },
  {
    price: "10",
    name: "Vendor",
    value: "vendor",
    icon: <ReactSVG src="/assets/icons/people.svg" />,
  },
  {
    price: "87",
    name: "Store",
    value: "store",
    icon: <ReactSVG src="/assets/icons/house.svg" />,
  },
  {
    price: "12",
    name: "Suppliers",
    value: "suppliers",
    icon: <ReactSVG src="/assets/icons/buildings.svg" />,
  },
  {
    price: "2.8k",
    name: "Total Sales",
    value: "sale",
    icon: <ReactSVG src="/assets/icons/chart.svg" />,
  },
];
const ProductStats = ({ product }) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          {product
            ? stats?.map((item, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="icon-rounded bg-light">{item.icon}</div>
                      <p className="fw-normal lh-lg">{item.name}</p>
                      <h3 className="fw-bold">
                        {item.sign == "€" ? item.sign : null}
                        {product[`${item.value}`]
                          ? product[`${item.value}`]
                          : 10}
                        {item.sign == "%" ? item.sign : null}
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      {/* <div className="product-stats">
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/coin.svg" />
            </div>
            <p>Cost Price</p>
            <h3>$34.19</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/discount-circle.svg" />
            </div>
            <p>Sale Price</p>
            <h3>$64.00</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/tag-2.svg" />
            </div>
            <p>Profit</p>
            <h3>$16.41</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/money-4.svg" />
            </div>
            <p>Profit Margin</p>
            <h3>55.00 %</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/people.svg" />
            </div>
            <p>Vendor</p>
            <h3>10</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/house.svg" />
            </div>
            <p>Store</p>
            <h3>87</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/buildings.svg" />
            </div>
            <p>Suppliers</p>
            <h3>12</h3>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="icon-rounded bg-light">
              <ReactSVG src="/assets/icons/chart.svg" />
            </div>
            <p>Total Sales</p>
            <h3>2.8K</h3>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default ProductStats;
