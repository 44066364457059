import React from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

const FAQ = () => {
  return (
    <React.Fragment>
      <div className="faq-section">
        <div className="container-sm">
            <h2 className="title">Frequently Asked Question</h2>
            <div className="grid" data-gap="25">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><Trans component={{ br: <br /> }}>question1</Trans></Accordion.Header>
                        <Accordion.Body>
                            <p><Trans component={{ br: <br /> }}>answer1</Trans></p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="n">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><Trans component={{ br: <br /> }}>question2</Trans></Accordion.Header>
                        <Accordion.Body>
                            <p><Trans component={{ br: <br /> }}>answer2</Trans></p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="n">
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><Trans component={{ br: <br /> }}>question3</Trans></Accordion.Header>
                        <Accordion.Body>
                            <p><Trans component={{ br: <br /> }}>answer3</Trans></p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FAQ;
