import React, { Fragment } from "react";
import Markup from "./jsx";


const App = () => {
  return (
    <Fragment>
      <Markup />
    </Fragment>
  );
};

export default App;
