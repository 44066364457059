import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

import Button from "../components/Common/Button";

import BenefitSection from "../components/home/BenefitSection";
import OrderProcess from "../components/home/OrderProcess";
import SpecialOffer from "../components/home/SpecialOffer";
import Testimonial from "../components/home/Testimonial";
import { useTranslation, Trans } from "react-i18next";
import PricingModel from "../components/home/PricingModel";
const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="home-page">
        <section className="home-banner">
          <LazyLoadImage
            className="bg-image"
            src="/assets/image/banner.jpg"
            alt="banner-image"
          />
          <div className="container">
            <h1>
              <Trans component={{ br: <br /> }}>title</Trans>
            </h1>
            <p>
              <Trans component={{ br: <br /> }}>subtitle</Trans>
            </p>
            <div className="text-center">
              <Button
                className={"btn-body m-auto btn-pricing"}
                Text={<Trans>contact</Trans>}
                URL={"arrow-right"}
                onClick={() => navigate("contact-us")}
              />
            </div>
          </div>
        </section>

        <BenefitSection />
        <OrderProcess />
        <SpecialOffer />
        <PricingModel />
        <Testimonial />
      </div>
    </React.Fragment>
  );
};

export default Home;
