import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button";
import PaymentType from "../Common/PaymentType";
import { useTranslation, Trans } from "react-i18next";

const SpecialOffer = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <section className="special-offer">
        <div className="grid" data-gap="40">
          <div>
            <h3 className="section-title text-center text-white">
            <Trans component={{ br: <br /> }}>titlespecialoffer</Trans>
            </h3>
            <p className="sub-heading text-center text-white">
            <Trans component={{ br: <br /> }}>subtitlespecialoffer</Trans>
            </p>
          </div>
          <PaymentType />
          <div className="grid text-center" data-gap="20">
            <p className="text-white">
            <Trans component={{ br: <br /> }}>footerespecialoffer</Trans>
            </p>
            <div className="h-list justify-content-center">
              <Button
                className={"btn-body btn-pricing"}
                Text={<Trans component={{ br: <br /> }}>Buttonpricing</Trans>}
                URL={"arrow-right"}
                onClick={() => navigate("/pricing")}
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SpecialOffer;
