import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import Button from "../Common/Button";

const ProductList = ({ List, setLoad, load }) => {
  return (
    <React.Fragment>
      <div className="grid" data-gap="25">
        <p className="product-count">{List?.length} Product by Best Seller</p>
        {/* <div className="product-list"> */}
        <div className="container">
          <div className="row gy-4">
            {List.map((item, index) => (
              <div
                className="col-md-4 col-sm-6 col-12"
                style={{ height: "100%" }}
              >
                <Link
                  to={`/detail-product/${item.id}`}
                  className="item"
                  key={item.id}
                >
                  <div className="image">
                    <LazyLoadImage src={item.file[0]} effect="blur" />
                  </div>
                  <h5>{item.name}</h5>
                </Link>
              </div>
            ))?.slice(0, load)}
          </div>
        </div>
        {/* </div> */}
        {load <= List?.length ? (
          <div className="button-holder">
            <Button
              Text={"Load More"}
              className={"btn-outline-primary mx-auto"}
              onClick={() => setLoad((prevload) => prevload + 6)}
            />
          </div>
        ) : (
          <div className="button-holder">
            <Button
              Text={"Load Less"}
              className={"btn-outline-primary mx-auto"}
              onClick={() => setLoad((prevload) => prevload - 6)}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductList;
