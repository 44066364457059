import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Donut from "../components/chart/Donut";
import HorizontalChart from "../components/chart/HorizontalChart";
import BreadCrumbs from "../components/Common/BreadCrumbs";
import ProductStats from "../components/Seller/ProductStats";
import { useParams } from "react-router-dom";
import { fireDB } from "../../config/firebase";
import { getDoc, doc } from "firebase/firestore";
import { useTranslation, Trans } from "react-i18next";

const DetailProduct = () => {
  const params = useParams();
  const [product, setProduct] = useState();
  useEffect(() => {
    if (params.productId) {
      handleProduct();
    }
  }, [params]);
  async function handleProduct() {
    const docRef = doc(fireDB, "products", params.productId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let inputData = { ...docSnap.data(), id: docSnap.id };

      setProduct(inputData);
    }
  }

  return (
    <React.Fragment>
      <div className="detail-product">
        <div className="product-detail-banner">
          <div className="grid" data-gap="40">
            <div className="container">
              <div className="grid" data-gap="25">
                <BreadCrumbs name={product?.name} D />
                <h1>{product?.name}</h1>
              </div>
            </div>
            <div className="container-lg">
              
              <div className="detail-images" data-gap="25">
                <div className="image item-1">
                  <LazyLoadImage src={product?.file[0]} />
                </div>
                <div className="grid" data-gap="25">
                  <div className="image item-2">
                    <LazyLoadImage
                      src={
                        product?.file[1] ? product?.file[1] : product?.file[0]
                      }
                    />
                  </div>
                  <div className="image item-3">
                    <LazyLoadImage
                      src={
                        product?.file[2] ? product?.file[2] : product?.file[0]
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="grid" data-gap="40">
            <div className="grid" data-gap="25">
              <h3 className="title-effect"><Trans component={{ br: <br /> }}>productoverview</Trans></h3>
              <p>{product?.description}</p>
            </div>
            <div className="divider"></div>
            <div className="grid" data-gap="25">
              <h3 className="title-effect"><Trans component={{ br: <br /> }}>titleanalytics</Trans></h3>
              <ProductStats product={product} />
            </div>
          </div>
        </div>
        <div className="container px-4">
          <div className="row mb-4">
            <div className="col-md-6 col-12">
              <Donut product={product} />
            </div>
            <div className="col-md-6 col-12">
              <HorizontalChart product={product} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailProduct;
