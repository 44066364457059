import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../components/Common/Button";
import FAQ from "../components/FAQ/FAQ";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useTranslation, Trans } from "react-i18next";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.body.classList.add("active-header");
    return function cleanup() {
      document.body.classList.remove("active-header");
    };
  }, []);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    data.to_name = "spsfullfilment";
    setLoading(true);
    emailjs
      .send("service_8fk3qjw", "template_jw5yq6o", data, "lDpMvVmtgtud1otoM")
      .then(
        (result) => {
          if (result.text) {
            setTimeout(() => {
              setLoading(false);
              navigate("/thanks");
            }, 2000);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );

    
  };
  const loader = (
    <div className="loading">
      <div className="spinner">
        <div
          className="spinner-border text-light bg-dark"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div className="contact-us">
        <div className="contact-us-banner">
          {loading ? loader : null}
          <div className="container-sm">
            <div className="grid" data-gap="15px">
              <h1><Trans component={{ br: <br /> }}>titlecontactus</Trans></h1>
              <p>
              <Trans component={{ br: <br /> }}>subtitlecontactus</Trans>
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid" data-gap="40">
                <div className="grid" data-gap="80">
                  <div className="row g-5">
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>name</Trans></label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("from_name", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>phonenumber</Trans></label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("from_phonenumber", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>email</Trans></label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("from_email", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label><Trans component={{ br: <br /> }}>yourquestion</Trans></label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("question", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-holder  d-flex justify-content-center">
                  <Button
                    Text={<Trans component={{ br: <br /> }}>contactusbutton</Trans>}
                    className={"btn-primary"}
                    URL={"arrow-right"}
                    type="submit"
                    disabled={!!loading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <FAQ />

        <div className="our-offices">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-12">
                <h3><Trans component={{ br: <br /> }}>Ouroffice</Trans></h3>
              </div>
              <div className="col-md-9 col-12">
                <div className="row">
                  <div className="col-md-4 col-12" data-gap="25">
                    <h4 className="py-2 fw-semibold"><Trans component={{ br: <br /> }}>Italy1</Trans></h4>
                    <div className="divider"></div>
                    <p className="py-3">
                    <Trans component={{ br: <br /> }}>Office1</Trans>
                    </p>
                  </div>
                  <div className="col-md-4 col-12">
                    <h4 className="py-2 fw-semibold"><Trans component={{ br: <br /> }}>Italy1</Trans></h4>
                    <div className="divider"></div>
                    <p className="py-3">
                    <Trans component={{ br: <br /> }}>Office2</Trans>
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
