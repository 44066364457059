import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import ShippingCardItem from "../Cards/ShippingCardItem";
import { useTranslation, Trans } from "react-i18next";

const ProductSlide = ({
  OrderType,
  DescIcon,
  title,
  desc,
  background,
  image,
  color,
  Items,
}) => {
  const [price, setPrice] = useState(Items[0]?.price);

  const handleClick = (...props) => {
    setPrice(props);
  };
  return (
    <React.Fragment>
      {/* <div className={`grid-col-2 ${OrderType}`}>
        <div className="shipping-info">
          <div className="grid" data-gap="80">
            <div className="grid" data-gap="25">
              <ReactSVG src={`/assets/icons/${DescIcon}.svg`} />
              <div className="grid" data-gap="15">
                <h4>{title}</h4>
                <h5>{desc}</h5>
              </div>
            </div>
            <div className="grid" data-gap="10">
              <p>Starting at</p>
              <div className="price">
                <span>$1.99</span> / per unit
              </div>
            </div>
          </div>
        </div>
        <div className="slider-wrapper">
          <ShippingCardItem />
        </div>
      </div> */}
      <div className="container">
        <div className={`row gx-5`}>
          <div
            className={`col-md-6 col-12  order-${
              OrderType === "reverse" ? 2 : 1
            } px-${OrderType === "reverse" ? 5 : 1}`}
          >
            <div className="grid" data-gap="80">
              <div className="grid" data-gap="25">
                <ReactSVG src={`/assets/icons/${DescIcon}.svg`} />
                <div className="grid" data-gap="15">
                  <h4 className="fs-2 fw-semibold lh-lg">{title}</h4>
                  <h5 className="lh-3 fw-normal">{desc}</h5>
                </div>
              </div>
              <div className="grid" data-gap="10">
                <p className=""><Trans component={{ br: <br /> }}>Startingat</Trans></p>
                <div className="price">
                  <span
                    className="fw-bolder fs-3"
                    style={{ color: background }}
                  >
                    €{price}
                  </span>{" "}
                  <Trans component={{ br: <br /> }}>Perunit</Trans>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1 col-0"></div>
          <div
            className={`col-md-5 col-12 p-5  rounded-3 d-flex justify-content-center order-${
              OrderType === "reverse" ? 1 : 2
            }`}
            style={{ background: background }}
          >
            <ShippingCardItem
              handleClick={handleClick}
              setPrice={setPrice}
              background={background}
              image={image}
              color={color}
              Items={Items}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductSlide;
