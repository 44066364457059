import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const BenefitCard = ({ title, desc, Icon, route }) => {
  return (
    <div className="card benefit-card card-hover">
      <div className="card-body">
        <div className="grid" data-gap="60">
          <div className="fr-auto" data-gap="15">
            <div className="card-title">{title}</div>
            <div className="bg-light icon-rounded">
              <ReactSVG src={`/assets/icons/${Icon}.svg`} />
            </div>
          </div>
          <div className="grid" data-gap="15">
            <p className="desc">{desc}</p>
            <div>
              <Link to={route} className="h-list read-more btn-hover" data-gap="8">
                Learn More{" "}
                <ReactSVG
                  className="svg-icon"
                  src="/assets/icons/arrow-right.svg"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitCard;
