import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

import Button from "../Common/Button";

const OrderProcessCard = ({ StepImage, title, desc, anchor, route }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="order-process-card">
        <div className="image">
          <LazyLoadImage src={`/assets/image/${StepImage}.png`} />
        </div>
        <div className="detail">
          <div className="grid" data-gap="40">
            <div className="grid" data-gap="15">
              <h4>{title}</h4>
              <p>{desc}</p>
            </div>
            <div>
              <Button
                className={"btn-body"}
                Text={anchor}
                URL={"arrow-right"}
                onClick={() => navigate(route)}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderProcessCard;
