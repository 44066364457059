import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import BenefitCard from "../Cards/BenefitsCard";
import Slider from "react-slick";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
const BenefitSection = () => {
  const { t } = useTranslation();
  let [slider] = useState(null);
  function next() {
    slider.slickNext();
  }
  function previous() {
    slider.slickPrev();
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="benefit-section py-120">
      <div className="container-sm">
        <h2 className="section-title text-center">
          <Trans component={{ br: <br /> }}>benefittitle</Trans>
        </h2>
        <h5 className="sub-heading text-center">
          <Trans component={{ br: <br /> }}>benefitsubtitle</Trans>
        </h5>
        <div className="container my-3 d-none d-sm-block">
          <div className="row">
            {t("benifit", { returnObjects: true }).map((item, index) => (
              <div className="col-md-6 col-12 py-2" key={index}>
                <BenefitCard
                  key={item.id}
                  title={item.title}
                  desc={item.description}
                  Icon={item.icon}
                  route={item.route}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="container my-3 d-block d-sm-none">
          <div className="row">
            <div className="col-12">
              <div
                className="justify-content-between d-flex"
                style={{ position: "relative" }}
              >
                <button
                  onClick={previous}
                  style={{
                    border: "1px solid blue",
                    borderRadius: "50%",
                    paddingBottom: "5px",
                    background: "blue",
                  }}
                >
                  <BsChevronLeft color="#fff" />
                </button>
                <button
                  onClick={next}
                  style={{
                    border: "1px solid blue",
                    borderRadius: "50%",
                    paddingBottom: "5px",
                    background: "blue",
                  }}
                >
                  <BsChevronRight color="#fff" />
                </button>
              </div>
            </div>
            <Slider {...settings} ref={(c) => (slider = c)}>
              {t("benifit", { returnObjects: true }).map((item, index) => (
                <div className="col-md-6 col-12 py-2" key={index}>
                  <BenefitCard
                    key={item.id}
                    title={item.title}
                    desc={item.description}
                    Icon={item.icon}
                    route={item.route}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <div className="card-holder">
          {Data.map((item) => (
            <BenefitCard
              key={item.id}
              title={item.title}
              desc={item.desc}
              Icon={item.icon}
              route={item.route}
            />
          ))}
        </div> */}
      </div>
    </section>
  );
};

export default BenefitSection;
