import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const OurServices = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <React.Fragment>
      <div className="our-services">
        <div className="services-banner">
          <div className="container-sm">
            <div className="grid" data-gap="15">
              <h1><Trans component={{ br: <br /> }}>Ourservicetitle</Trans></h1>
              <p>
              <Trans component={{ br: <br /> }}>Ourservicesubtitle</Trans>
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row my-5 py-5">
            <div className="col-md-6 col-12 d-flex  flex-column justify-content-center">
              <h4 className="fs-2 fw-bold lh-lg" id="complete-service">
              <Trans component={{ br: <br /> }}>Completeservicetitle</Trans>
              </h4>
              <p className="fs-4 fw-normal">
              <Trans component={{ br: <br /> }}>Completeservice</Trans>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <div className="image">
                <LazyLoadImage src="/assets/image/service-1.png" />
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-md-6 col-12">
              <div className="image">
                <LazyLoadImage src="/assets/image/service-2.png" />
              </div>
            </div>
            <div className="col-md-6 col-12 d-flex  flex-column justify-content-center">
              <h4
                className="fs-2 fw-bold lh-lg"
                id="your-goods-to-our-warehouse"
              >
                <Trans component={{ br: <br /> }}>YourGoodstoOurWarehousetitle</Trans>
              </h4>
              <p className="fs-4 fw-normal">
              <Trans component={{ br: <br /> }}>YourGoodstoOurWarehousesubtitle</Trans>
              </p>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-md-6 col-12 d-flex  flex-column justify-content-center">
              <h4
                className="fs-2 fw-bold lh-lg"
                id="our-goods-to-your-warehouse"
              >
                <Trans component={{ br: <br /> }}>OurGoodstoYourWarehousetitle</Trans>
              </h4>
              <p className="fs-4 fw-normal">
              <Trans component={{ br: <br /> }}>OurGoodstoYourWarehousesubtitle</Trans>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <div className="image">
                <LazyLoadImage src="/assets/image/service-3.png" />
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-md-6 col-12">
              <div className="image">
                <LazyLoadImage src="/assets/image/service-4.png" />
              </div>
            </div>
            <div className="col-md-6 col-12 d-flex  flex-column justify-content-center">
              <h4 className="fs-2 fw-bold lh-lg"><Trans component={{ br: <br /> }}>SalesAssistancetitle</Trans></h4>
              <p className="fs-4 fw-normal">
              <Trans component={{ br: <br /> }}>SalesAssistancesubtitle</Trans>
              </p>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-md-6 col-12 d-flex  flex-column justify-content-center">
              <h4 className="fs-2 fw-bold lh-lg"><Trans component={{ br: <br /> }}>Dropshippingtitle</Trans></h4>
              <p className="fs-4 fw-normal">
              <Trans component={{ br: <br /> }}>Dropshippingsubtitle</Trans>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <div className="image">
                <LazyLoadImage src="/assets/image/service-5.png" />
              </div>
            </div>
          </div>
          <div className="row my-5 py-5">
            <div className="col-md-6 col-12">
              <div className="image">
                <LazyLoadImage src="/assets/image/service-6.png" />
              </div>
            </div>
            <div className="col-md-6 col-12 d-flex  flex-column justify-content-center">
              <h4 className="fs-2 fw-bold lh-lg"><Trans component={{ br: <br /> }}>Callcentertitle</Trans></h4>
              <p className="fs-4 fw-normal">
              <Trans component={{ br: <br /> }}>Callcentersubtitle</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OurServices;
