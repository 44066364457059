import React from "react";
/// React router dom
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Helmet } from "react-helmet";
import ScrollToTop from "./components/Common/ScrollToTop";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Category from "./pages/Admin/Category";
import CreateCategory from "./pages/Admin/CreateCategory";
import Product from "./pages/Admin/Product";
import CreateProduct from "./pages/Admin/CreateProduct";

import Home from "./pages/home";
import AboutUs from "./pages/AboutUs";
import BestSeller from "./pages/BestSeller";
import DetailProduct from "./pages/DetailProduct";
import Pricing from "./pages/pricing";
import ContactUs from "./pages/ContactUs";
import ProductResearch from "./pages/productResearch";
import OurServices from "./pages/services";
import Order from "./pages/Order";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Thanks from "./pages/Thanks";
import Admin from "./pages/Admin";

import { Outlet } from "react-router-dom";
const Root = () => {
  return (
    <>
      <Header />
      <ScrollToTop />
      <Outlet />
      <Footer />
    </>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },

      {
        path: "best-seller",
        element: <BestSeller />,
      },
      {
        path: "detail-product/:productId",
        element: <DetailProduct />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "thanks",
        element: <Thanks />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "our-services",
        element: <OurServices />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "product-research",
        element: <ProductResearch />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
    ],
  },
  {
    element: <Admin />,
    path: "/admin",
    children: [
      {
        path: "category",
        element: <Category />,
      },
      {
        path: "createcategory",
        element: <CreateCategory />,
      },
      {
        path: "createcategory/:categoryId",
        element: <CreateCategory />,
      },
      {
        path: "product",
        element: <Product />,
      },
      {
        path: "createproduct",
        element: <CreateProduct />,
        // loader: redirectIfUser,
      },
      {
        path: "createproduct/:productId",
        element: <CreateProduct />,
        // loader: redirectIfUser,
      },
      // {
      //   path: "logout",
      //   action: logoutUser,
      // },
    ],
  },
  {
    element: (
      <main style={{ padding: "1rem" }}>
        <p>There's nothing here!</p>
      </main>
    ),
    path: "*",
  },
]);

const Markup = () => {
  return (
    <>
      <div className="site-holder">
        <Helmet>
          <title>SPS</title>
        </Helmet>
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default Markup;
