import React from "react";

import { ReactSearchAutocomplete } from "react-search-autocomplete";

const ProductSearch = ({ setValue, categories }) => {
  const handleOnSearch = (string, results) => {
    setValue("search", string);
  };

  const handleOnHover = (result) => {
    // the item hovered

    setValue("search", result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    setValue("search", item);
  };

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="product-search">
        <ReactSearchAutocomplete
          items={categories}
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSearch}
          onFocus={handleOnFocus}
          autoFocus
          formatResult={formatResult}
          styling={{
            height: "64px",
            margin: "auto",
            border: "1px solid #f2f2f2",
            boxShadow: "0px 12px 40px rgba(0, 0, 0, 0.08) !important",
            borderRadius: "30px",
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default ProductSearch;
