import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Button from "../components/Common/Button";
import { useNavigate } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

import { useTranslation, Trans } from "react-i18next";
const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="grid" data-gap="80">
          <div className="grid" data-gap="40">
            <div className="grid text-center" data-gap="15">
              <h2><Trans component={{ br: <br /> }}>Titlefooter</Trans></h2>
              <p>
              <Trans component={{ br: <br /> }}>Subtitlefooter</Trans>
              </p>
            </div>
            <div className="button-holder text-center">
              <Button
                className={"btn-primary m-auto"}
                Text={<Trans component={{ br: <br /> }}>FooterOrderNow</Trans>}
                onClick={() => navigate("/order")}
                URL={"arrow-right"}
              />
            </div>
            <div className="divider"></div>
          </div>

          <div className="footer-nav">
            <div className="row align-items-start g-5">
              <div className="col-md-4 col-12 mr-2">
                <h4><Trans component={{ br: <br /> }}>Footertitle2</Trans></h4>
                <div className="input-wrapper position-relative">
                  <input
                    type={"email"}
                    placeholder="Email"
                    className="form-control"
                  />
                  <div className="icon" onClick={() => navigate("/contact-us")}>
                    <ReactSVG src="/assets/icons/arrow-right.svg" />
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-2 col-sm-4 col-6">
                <h3><Trans component={{ br: <br /> }}>FooterCompany</Trans></h3>
                <ul>
                  {t("headers", { returnObjects: true })?.map((item) => (
                    <li>
                      <Link to={item.link} className="btn-hover text-white">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  {/* <li>
                    <Link to="/best-seller" className="btn-hover text-white">
                      Best Sellers
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className="btn-hover text-white">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/product-research"
                      className="btn-hover text-white"
                    >
                      Research
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-services" className="btn-hover text-white">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" className="btn-hover text-white">
                      Contact Us
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <h3><Trans component={{ br: <br /> }}>FooterService2</Trans></h3>
                <ul>
                  <li>
                    <Link
                      to="/our-services#complete-service"
                      className="btn-hover text-white"
                    >
                      <><Trans component={{ br: <br /> }}>FooterCompleteService</Trans> </>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/our-services#your-goods-to-our-warehouse"
                      className="btn-hover text-white"
                    >
                      <Trans component={{ br: <br /> }}>FooterCompany</Trans>Your Goods to Our Warehouse
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/our-services#our-goods-to-your-warehouse"
                      className="btn-hover text-white"
                    >
                      <Trans component={{ br: <br /> }}>FooterGoodstous</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 col-sm-4 col-6">
                <h3><Trans component={{ br: <br /> }}>FooterOurPlace</Trans></h3>
                <ul>
                  <li>
                    <Link to="#" className="btn-hover text-white">
                    <Trans component={{ br: <br /> }}>FooterAdress</Trans>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="btn-hover text-white">
                    <Trans component={{ br: <br /> }}>FooterPhone</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="container">
              <div className="row">
                <div className="col-md-3 col-12"></div>
                <div className="col-md-3 col-12"></div>
                <div className="col-md-3 col-12"></div>
                <div className="col-md-3 col-12"></div>
              </div>
            </div> */}
          <div className="d-flex align-items-center gap-2 justify-content-md-between justify-content-center">
            <div className="h-list social-icons" data-gap="15">
              <a
                href="https://www.instagram.com/spsfulfillment/"
                target="_blank"
                className="icon-rounded icon-hover "
              >
                <FiInstagram className="instagram" size={30} />
                {/* <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.167 4.66699H9.83366C7.25633 4.66699 5.16699 6.75633 5.16699 9.33366V18.667C5.16699 21.2443 7.25633 23.3337 9.83366 23.3337H19.167C21.7443 23.3337 23.8337 21.2443 23.8337 18.667V9.33366C23.8337 6.75633 21.7443 4.66699 19.167 4.66699Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.5 17.5C16.433 17.5 18 15.933 18 14C18 12.067 16.433 10.5 14.5 10.5C12.567 10.5 11 12.067 11 14C11 15.933 12.567 17.5 14.5 17.5Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.75 8.75V8.751"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> */}
              </a>

              <a
                href="https://www.facebook.com/profile.php?id=100089592223488"
                target="_blank"
                className="icon-rounded icon-hover "
              >
                <FiFacebook className="facebook" size={30} />
                {/* <svg
                  width="25"
                  height="28"
                  viewBox="0 0 25 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 11.6667V16.3333H10.5V24.5H14.5V16.3333H17.5L18.5 11.6667H14.5V9.33333C14.5 9.02391 14.6054 8.72717 14.7929 8.50838C14.9804 8.28958 15.2348 8.16667 15.5 8.16667H18.5V3.5H15.5C14.1739 3.5 12.9021 4.11458 11.9645 5.20854C11.0268 6.30251 10.5 7.78624 10.5 9.33333V11.6667H7.5Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> */}
              </a>

              <a
                href="https://twitter.com/SPSFulfillment"
                target="_blank"
                className="icon-rounded icon-hover"
              >
                <FiTwitter className="twitter" size={30} />
                {/* <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    14.5393 22.7694C18.716 21.556 22.1483 18.4259 23.4655 13.737C23.8584 12.311 24.0535 10.8377 24.0453 9.35853C24.043 9.06803 25.807 6.12453 26.1663 4.67669V4.67786Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> */}
              </a>
            </div>
            <p className="m-0 text-center">
              <Link to="/privacy">Privacy Policy / Cookie Policy</Link>{" "}
              <Link to="/terms">Termini e Condizioni</Link>
              - P.Iva e C.F. 12683190966
              <br /> info@spsfulfillment.com
              {/* &copy; SPS Fulfillment S.R.L.S || All Right Reserved */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
